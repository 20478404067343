import V2Results from "../layouts/V2Results";
import { Route, Switch } from "react-router-dom";
import { JSX } from 'react'
import Tools from "../pages/tools";

export default function ToolsRoute(): JSX.Element {
    return (
        <Route path="/tools">
            <V2Results>
                <Switch>
                    <Route path="/tools/:category/:industry?" component={Tools} />
                </Switch>
            </V2Results>
        </Route>
    )
}