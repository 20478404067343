import React, { Component, useState, useCallback } from 'react'
import giftdb from '../../assets/images/giftdb.png'
import giftdb_white from '../../assets/images/giftdb_white.svg'
import ReactTooltip from 'react-tooltip'

const OfferToolTip = (props) => {
  const data = props.data[0]

  return (
    <div className="offertip">
      <ReactTooltip
        id="global"
        className="tooltip"
        aria-haspopup="true"
        effect="solid"
      >
        <span className="tooltiptitle">{data.title}</span>
        <span>View listing to find out more</span>
      </ReactTooltip>
      <img
        data-tip
        data-for="global"
        alt="special offer"
        src={props.colour === 'blue'}
      />
    </div>
  )
}

export default OfferToolTip
