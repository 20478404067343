const Loading = () => {
  return (
    <div className="gc no-gap splashscreen">
      <div className="d-2-12 t-3-11 m-1-13 ">
        <svg className="splash-spinner" viewBox="0 0 50 50">
          <circle
            className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          ></circle>
        </svg>
      </div>
    </div>
  )
}
export default Loading
