import { Component } from 'react'
import './directory.scss'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Listbox } from 'aria-components'

class Directory extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: [],
      loading: true,
      error: '',
      menuActive: false,
      sendlink: false,
      redirectlink: '',
    }
  }

  sent = () => {
    if (this.state.sendlink && this.state.sent) {
      this.setState({ sendlink: false, sent: false })
    }
  }

  componentDidUpdate () {
    this.sent()
  }

  fetchCategories = async () => {
    try {
      const response = await this.props.query()
      const data = await response.data

      this.setState({ data: data }, () => {
        const controller = document.getElementById(`${this.props.id}_button`)

        const target = document.getElementById(`${this.props.id}_ul`)

        new Listbox({
          controller,
          target,
          onStateChange: (e) => {
            if (!e.expanded) {
              e.activeDescendant.click()
            }
          },
        })

      })
    } catch (error) {
      console.error(error)
    }
  }

  componentDidMount () {
    if (this.props.display) {
      this.fetchCategories()
    }
    this.setState({ selected: this.props.button_text })

  }

  render () {
    return (
      <div className="table_center">
        <div className={`drop-down`}>
          <span className="drop-down__button">
            {' '}
            <button id={`${this.props.id}_button`} className="controller_list">
              {unescape(this.state.selected)}{' '}
            </button>
            <FontAwesomeIcon
              icon={!this.state.menuActive ? faChevronDown : faChevronDown}
            />
          </span>

          <div className="drop-down__menu-box">
            <ul
              id={`${this.props.id}_ul`}
              className="drop-down__menu controller_UL"
            >
              <li key={`${this.props.id}_li`} data-name="profile"
                  className="drop-down__item" hidden>
                {this.props.button_text}
              </li>
              {
                this.state.data
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((category, i) => {
                    return (
                      <li
                        onClick={() => this.props.SetTitle(category.slug)}
                        data-name="profile"
                        className="drop-down__item"
                        key={`d_${category.name}_${i}`}
                      >
                        {unescape(category.name)}
                      </li>
                    )
                  })}
            </ul>

          </div>
        </div>
      </div>
    )
  }
}

export default Directory
