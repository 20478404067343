import Profile from "../layouts/profile";
import { Redirect, Route, Switch } from "react-router-dom";
import { JSX } from 'react'
import searchResults from "../pages/searchResults";

export default function SearchRoute(): JSX.Element {
    return (
        <Route path="/search">
            <Profile>
                <Switch>
                    <Route path="/search/results" exact component={searchResults} />
                    <Route render={() => <Redirect to="/" />} />
                </Switch>
            </Profile>
        </Route>
    )
}