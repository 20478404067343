import React, { Component } from 'react'
import Search from '../components/search/search'
import Result from '../components/search/results'
import { Helmet } from 'react-helmet'
import { SITE_URL } from '../constraints'
import ToolsCTA from '../content/ToolsCTA'

class searchResults extends Component {
  render () {
    let query = new URLSearchParams(window.location.search).get('search')

    return (
      <div id="results_page">
        <Helmet>
          <meta
            name="description"
            content="Find the right tools to help you run your small business. Quickly find peer reviewed apps and software to help to manage and grow your business. Get started."
          />
          <meta
            property="og:image"
            content={`${SITE_URL}/TheRightTool.jpg`}
          />
          <title>Search Results - The Right Tool</title>
        </Helmet>
        <Search />
        <Result data={query} />
        <ToolsCTA />
      </div>
    )
  }
}

export default searchResults
