import React, { useEffect, useState } from "react";
import Button from "../button/button";
import { Swiper, SwiperSlide } from "swiper/react";
import NewListing from "../newlisting/newlisting";
import Strapi from "../../services/Strapi";
import { convertServicesList } from "../../util/api_converters";

export default function ImageCTA(): React.JSX.Element {
    const [data, setData] = useState<Record<string, any>[]>([]);

    useEffect(() => {
        Strapi
            .get('/services/random/4')
            .json((json) => convertServicesList(json.data))
            .then(data => setData(data))
    }, [])


    const scrollTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    if (data.length === 0) {
        return (<></>)
    }

    return (
        <div id="imageCTAcontainer"
             className="gc no-gap center_all background_blue ">
            <div className="d-2-7 t-3-11 m-2-12 content ">
                <h2 className="white">Get set up by the experts</h2>
                <p className="main_p white">
                    Find cloud experts to help you set them up. So you can get the help
                    when you need it.
                </p>
                <div className="pointer" onClick={() => scrollTop()}>
                    <Button text="Find Cloud Experts" color="white" link={null} />
                </div>
            </div>

            <div
                id="imageSpacing"
                className="d-8-12 t-3-11 m-1-13 imageCTA t-hide m-hide"
            >
                <Swiper
                    className="mySwiper swiper-container"
                    spaceBetween={0}
                    centeredSlides={true}
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                >
                    {data.map((provider) => (
                        <SwiperSlide key={`slide_provider_${provider.id}`}>
                            {' '}
                            <NewListing service={provider} link={`/profile/cloud-expert`} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}