import React, { useEffect, useState } from 'react'
import NewListing from '../components/newlisting/newlisting'
import FeaturedIndustry from '../components/FeaturedIndustry/featuredindustry'
import Toggle from '../components/toggle/newtoggle'
import SmallCTA from '../components/small_cta/small_cta'
import Loading from '../components/loading/loading'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from 'react-helmet'
import fadeInElements from '../helpers/fadeInElements'
import { API_URL } from '../constraints'
import {
  convertCategory,
  convertIndustry,
  convertSubcategory,
} from '../util/api_converters'
import { getEntryBySlug } from '../util/api'

const Tools = (props) => {
  const [category, setCategory] = useState(props.match.params.category)
  const [LoadingServicesAndSubCategories, setLoadingServicesAndSubCategories] =
    useState(true)
  const [LoadingIndustry, setIndustryLoading] = useState(false)
  const [activeApps, setActiveApps] = useState(true)
  const [industry, setIndustry] = useState(
    props.match.params.industry ? props.match.params.industry : null,
  )
  const [subcategories, setSubcategories] = useState([])
  const [industryData, setIndustryData] = useState([])
  const [showFeatured, setShowFeatured] = useState(false)
  const [services, setServices] = useState([])

  const fetchData = async () => {
    let slug = category

    if (industry) {
      slug += `/${industry}`
    }

    await getEntryBySlug(slug, 'category', convertCategory)
      .then(cat => {
        if (!cat) {
          console.error(`Category not found ${slug}`)
          window.location.href = '/'
        }
        if (cat) {
          if (cat.subcategories) {
            cat.subcategories = cat.subcategories.map(sub => {
              if (sub.apps && sub.apps.length > 3) {
                sub.apps = sub.apps.slice(0, 3)
              }
              return sub
            })
            setSubcategories(cat.subcategories ?? [])
            setServices(cat.services ? cat.services : [])
            setLoadingServicesAndSubCategories(false)
          }
        }
      })
  }

  const fetchSubcategory = async (subcategory, i) => {
    if (subcategory.slug) {
      let fetchUrl = API_URL + `/api/subcategory/${subcategory.slug}`
      if (industry) {
        fetchUrl += `/${industry}`
      }

      await fetch(fetchUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error, status = ${response.status}`)
          }

          return response.json()
        })
        .then(responseData => {
          if (responseData) {
            return convertSubcategory(responseData)
          }

          throw new Error('Data error')
        })
        .then(convertedData => {
          // Get the next slice of apps
          const currentApps = subcategories[i].apps.length
          const posEnd = currentApps + 3
          let maxNumber = Math.min(convertedData.total_app_count, posEnd)

          convertedData.apps = convertedData.apps.slice(0, maxNumber)
            .map((app, p) => {
              app.show = p < currentApps

              return app
            })

          subcategories[i].apps = convertedData.apps
          updateAndRender([...subcategories])
        })
        .catch(err => {
          console.error('sub err', err)
          window.location.href = '/'
        })
    }
  }

  const updateAndRender = (newData) => {
    setSubcategories(newData)
    fadeInElements('animate')
  }

  const fetchIndustry = async () => {
    try {
      if (industry) {
        setShowFeatured(false)

        await getEntryBySlug(industry.toLowerCase(), 'industry',
          convertIndustry, { fetchFeatured: true })
          .then(data => {
            setIndustryData(data)
            if (data.featured_apps.length > 1) {
              setShowFeatured(true)
            }
          })
          .finally(() => {
            setIndustryLoading(false)
          })
      }
    } catch (error) {
      console.error(error.message)
    }
  }

  const change = (arg) => {
    setActiveApps(arg)
  }

  useEffect(() => {
    setLoadingServicesAndSubCategories(true)

    setCategory(props.match.params.category)

    if (props.match.params.industry) {
      setIndustryLoading(true)
      setIndustry(props.match.params.industry)
    }
  }, [props.match.params, props.location])

  useEffect(() => {
    if (category) {
      fetchData()
    }

    if (industry) {
      fetchIndustry()
    }
  }, [industry, category])

  const ServicesPreview = (props) => {
    if (services.length < props.end) return null
    return (
      <>
        <div className="d-2-12 t-2-12 m-2-12 ">
          <div className="split_between">
            <span className="cloudexperts_preview">Cloud Experts</span>
          </div>
        </div>
        <div className="d-2-12 t-2-12 m-1-13 gc2 profile">
          {services.map((service, index) => {
            //Clean this up belo
            if (index >= props.end || index < props.start) {
              return null
            }

            return (
              <React.Fragment key={'service_preview_' + index}>
                <NewListing service={service}
                            link={`/profile/cloud-expert`}
                            blue={true}
                />
              </React.Fragment>
            )
          })}
        </div>
      </>
    )
  }

  const Apps = () => {
    return (
      <>
        <div id="maincontentskip"
             className={showFeatured ? 'opacityfade' : 'hide'}>
          <div className={'gc  no-gap center_all listing_container'}>
            {showFeatured && <FeaturedIndustry data={industryData} />}
          </div>
        </div>
        <div className={showFeatured ? ' background_supergrey' : ''}>
          <div className={'gc  no-gap center_all listing_container'}>
            {subcategories.map((item, i) => {
              if (item.apps.length < 1) return null

              return (
                <React.Fragment key={`${i}${item.name}`}>
                  {i === 3 && <ServicesPreview start={0} end={3} />}
                  {i === 6 && <ServicesPreview start={3} end={6} />}
                  {i === 9 && <ServicesPreview start={6} end={9} />}
                  <div className="d-2-12 t-2-12 m-2-12 hr_parent">
                    {' '}
                    <hr />
                  </div>

                  <div className="d-2-12 t-2-12 m-2-12 ">
                    <div className="split_between">
                      <h2>{item.name}</h2>
                    </div>
                  </div>
                  <div key={item.name}
                       className="d-2-12 t-2-12 m-1-13 gc2 profile">
                    {item.apps.map((app, index) => {
                      return (
                        <React.Fragment key={'app_' + index}>
                          <NewListing app={app}
                                      className={(index > 2 && !app.show)
                                        ? 'zeroopacity animate'
                                        : ''}
                                      link={`/profile/app`}
                          />
                        </React.Fragment>
                      )
                    })}
                  </div>
                  <div className="d-2-12 t-2-12 m-2-12 ">
                    <div className="split_between">
                      <h2></h2>

                      <span
                        className={`${
                          item.apps.length === item.total_app_count
                            ? 'hide'
                            : 'blue pointer strong_span'
                        }`}
                        onClick={() => fetchSubcategory(item, i)}
                      >
                        {item.total_app_count > 3 && item.apps.length <
                          item.total_app_count && (
                            <>
                              Show {Math.min(
                              item.total_app_count - item.apps.length,
                              3)} More{' '}
                              <FontAwesomeIcon
                                icon={faLongArrowAltRight} />{' '}
                            </>
                          )}
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              )
            })}
          </div>
          <SmallCTA
            sub={'Not sure where to start? Try the Free Tool Finder'}
            button_text={'Try the free tool finder'}
            button_link={'/free-tool-finder'}
          />
        </div>
      </>
    )
  }

  const Services = () => {
    return (
      <div id="maincontentskip" className="opacityfade">
        <div className={'gc  no-gap center_all listing_container'}>
          <div className="d-2-12 t-2-12 m-2-12 text_center">
            <h2>{services.length > 0 ? 'Cloud Experts' : 'Empty'}</h2>
          </div>
          <div className="d-2-12 t-2-12 m-1-13 gc2 profile">
            {services.map((service, index) => {
              if (services.length < 1) return <p>No apps</p>
              return (
                <React.Fragment key={'service_' + index}>
                  <NewListing service={service}
                              link={`/profile/cloud-expert`}
                  />
                </React.Fragment>
              )
            })}
          </div>
        </div>
        <SmallCTA
          sub={'Not sure where to start? Try the Free Tool Finder'}
          button_text={'Try the free tool finder'}
          button_link={'/free-tool-finder'}
        />
      </div>
    )
  }

  const loading = [
    LoadingIndustry,
    LoadingServicesAndSubCategories,
  ]

  if (loading.every((x) => !x)) {
    return (
      <>
        <Helmet>
          <title>
            {' '}
            Find the best {industry ? industry : ''} apps & cloud-experts for
            your business - The Right Tool
          </title>
          <meta
            property="og:image"
            content={
              industry
                ? `https://therighttool.co.nz/TheRightTool_Meta_${industry}.png`
                : 'https://therighttool.co.nz/TheRightTool.jpg'
            }
          />
          <meta
            name="description"
            content={`Real reviews for ${
              industry ? industry : ''
            }} apps to help you find the tools to help you manage your ${
              industry ? industry : ''
            } business. Aotearoa’s leading ${
              industry ? industry : ''
            } app directory. Quickly find the best ${
              industry ? industry : ''
            } apps.`}
          />
        </Helmet>
        {<Toggle activeApps={activeApps} change={change} />}
        {!activeApps ? <Services /> : <Apps />}
      </>
    )
  } else {
    return (
      <>
        {<Toggle activeApps={activeApps} change={change} />}

        <Loading />
      </>
    )
  }
}

export default Tools
