import Profile from "../layouts/profile";
import { Route, Switch } from "react-router-dom";
import { JSX } from 'react'
import profileapps from "../pages/profileapps";
import profileservices from "../pages/profileservices";

export default function ProfileRoute(): JSX.Element {
    return (
        <Route path="/profile">
            <Profile>
                <Switch>
                    <Route
                        path="/profile/app/:handle"
                        exact
                        component={profileapps}
                    />
                    <Route
                        path="/profile/cloud-expert/:handle"
                        exact
                        component={profileservices}
                    />
                </Switch>
            </Profile>
        </Route>
    )
}