import React, { Component } from 'react'
import './filter.scss'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Listbox } from 'aria-components'
import { getCategoryOrIndustry } from '../../helpers/tools'

class Filter extends Component {
  constructor (props) {
    super(props)
    this.state = {
      menuActive: false,
      data: [],
      loading: true,
      selected: 'Select an Industry',
      default: 'Select an Industry',
    }
  }

  componentDidMount () {
    const filters = getCategoryOrIndustry()
    if (this.props.id === 'main_categoryDrop') {
      this.setState({ selected: filters.category, default: filters.category })
    } else {
      if (filters.industry) {
        this.setState({ selected: filters.industry, default: filters.industry })
      } else {
        this.setState({
          selected: 'Select an Industry',
          default: 'Select an Industry',
        })
      }
    }

    this.loadSelectList()
  }

  loadSelectList = async () => {
    // Uses the provided Query prop to load the select items, should be called once on mount
    if (this.props.query) {
      return await this.props.query()
        .then(response => {
          if (response && response.data) {
            response.data.sort((a, b) => a.name.localeCompare(b.name))
            return response.data

          }

          return []
        })
        .then(data => {
          return this.setState(
            { data },
            () => {
              const controller = document.getElementById(`${this.props.id}`)
              const target = document.getElementById(`${this.props.idlist}`)

              if (controller && target) {
                return new Listbox({
                  controller,
                  target,
                })
              }
            },
          )
        })
    }

    return null
  }

  selectFilter (filterItem) {
    if (filterItem.name === this.state.selected) return null

    this.setState({
      selected: filterItem.name,
      selectedValue: filterItem.slug,
    })

    this.props.setFilter(filterItem.slug)
  }

  render () {
    const select = this.state.selected.replace(/-/g, ' ')
    return (
      <div className={`filter_drop-down`}>
        <span
          id="filter_dropDown"
          className="filter_drop-down__button pointer filter_drop-down__name"
        >
          {' '}
          <button id={`${this.props.id}`} className="filterul">
            {decodeURI(select)}
          </button>
          {' '}
          &nbsp;
          <FontAwesomeIcon
            icon={!this.state.menuActive ? faChevronDown : faChevronDown}
          />
        </span>

        <div className="filter_drop-down__menu-box">
          <ul id={`${this.props.idlist}`} className="filter_drop-down__menu">
            {this.state.data.map((filterItem, i) => {
              return (
                <li key={`profile_${i}`}
                    onClick={(i) => {
                      this.selectFilter(filterItem)
                    }}
                    data-name="profile" value={filterItem.slug}
                    className="filter_drop-down__item">
                  {filterItem.name}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }
}

export default Filter
