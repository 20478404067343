import { JSX, useEffect, useState } from "react";
import Cookies from "../../services/Cookies";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import "./WishlistMenu.scss"
import classNames from "classnames";
import { API_URL } from "../../constraints";
import { convertLikeList } from "../../util/api_converters";
import WishlistMenuItem from "./WishlistMenuItem";
import { ListingCollectionType } from "../../types/listing";

export function WishlistMenu(): JSX.Element {
    const token = Cookies.get('AUTH_TOKEN')
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [activeMenu, setActiveMenu] = useState<ListingCollectionType>('apps');
    const [listingData, setListingData] = useState<any[]>([])


    // Load like data on menu show
    useEffect(() => {
        if (showMenu) {
            const fetchOpts = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${token}`,
                }
            }
            fetch(`${API_URL}/api/likes/${activeMenu}`, fetchOpts)
                .then(res => res.json())
                .then(data => convertLikeList(data))
                .then(convertedData => convertedData.map((item: Record<string, any>) => ({
                    likeId: item.id,
                    name: item.listing.name,
                    url: item.listing.url,
                    logo: item.listing.logo,
                    label: (item.listing.contentType === 'app') ?
                        item.listing.subcategories.flatMap((sc: Record<string, any>) => sc.name) :
                        'Cloud Expert'
                })))
                .then(processedData => setListingData(processedData))
                .catch(err => console.error(err));
        }
    }, [showMenu, activeMenu, setListingData, token])

    if (!token) {
        return (<></>);
    }

    return (
        <div className="wishlist-menu">
            <button onClick={() => setShowMenu(!showMenu)}>
                <FontAwesomeIcon icon={faHeart} />
            </button>

            <section className={"wishlist-menu__anchor"}>
                {showMenu && (
                    <div className={"wishlist-menu__floating-menu--visible"}>
                        <menu className={"wishlist-menu__floating-menu__bar"}>
                            <button className={classNames({'selected': activeMenu === 'apps'})}
                                    onClick={() => setActiveMenu('apps')}>
                                Apps
                            </button>

                            <button className={classNames({'selected': activeMenu === 'services'})}
                                    onClick={() => setActiveMenu('services')}>
                                Cloud Experts
                            </button>
                        </menu>

                        <section>

                            {listingData && listingData.map((item: any) => (
                                <WishlistMenuItem key={item.likeId}
                                                  url={item.url}
                                                  label={item.label}
                                                  name={item.name}
                                                  logo={item.logo}
                                                  likeId={item.likeId} />

                            ))}
                        </section>
                    </div>
                )}
            </section>
        </div>
    )
}