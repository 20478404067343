import { Component } from 'react'
import './cta.scss'
import Button from '../button/button'

class CTA extends Component {

  render () {
    const main_text = this.props.text
    const sub = this.props.sub
    const button_text = this.props.button_text
    const button_link = this.props.button_link

    return (
      <div
        className="gc no-gap gradient_blue_cta center_all content"
      >
        <div className="d-4-10 t-3-11 m-2-12 text_center">
          <h2 className="white">{main_text}</h2>
          <p className="white main_p">{sub}</p>
          <Button text={button_text} color="blue" link={button_link} />
        </div>
      </div>
    )
  }
}

export default CTA
