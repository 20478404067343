// NB: This is a js copy from the admin panel.
// If any changes are made here, please copy them to there
// and vice versa
const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/

export const getStoreItem = (key) => {
  const rawItem = localStorage.getItem(btoa(key))
  if (rawItem && base64regex.test(rawItem)) {
    const item = JSON.parse(atob(rawItem))
    if (item && item.value && item.timestamp) {
      if (item.timestamp > new Date().getTime()) {
        return item.value
      }

      localStorage.removeItem(btoa(key))
    }
  }

  return null
}

export const setStoreItem = (key, value, exdays = 30) => {
  const d = new Date()
  d.setTime((d.getTime() + (exdays * 24 * 60 * 60 * 1000)))
  localStorage.setItem(btoa(key), btoa(JSON.stringify({
    value,
    timestamp: d.getTime(),
  })))
}

export default {
  set: setStoreItem,
  get: getStoreItem,
  remove: (key) => localStorage.removeItem(btoa(key)),
  clear: () => {
    localStorage.clear()
  },
}