import { Component } from 'react'
import ReactStars from 'react-rating-stars-component'
import './reviews.scss'

class Reviews extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const comment = this.props.data.comment
    const stars = this.props.data.rating

    return (
      <div className="reviews">
        <div className="review_contaner">

          <ReactStars
            value={stars ? stars : 0}
            size={30}
            starSpacing="1px"
            activeColor={'#FAAA00'}
            edit={false}
          />
          <div>
            <span className="qoute" itemProp="review">"{comment}"</span>
          </div>
        </div>

      </div>
    )
  }
}

export default Reviews
