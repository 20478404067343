import { API_URL, SITE_URL } from '../constraints'
import { isArray } from 'lodash'
import { getEntriesById } from './api'

function flattenStrapiObj (obj) {
  if (obj.id && obj.attributes) {
    return { id: obj.id, ...obj.attributes }
  }

  if (obj.data && obj.data.length === 0) {
    return obj.data
  } else if (obj.data && obj.data.length > 0) {
    return obj.data.map(dataObj => flattenStrapiObj(dataObj))
  } else if (isArray(obj) && obj.length > 0) {
    return obj.map(item => flattenStrapiObj(item))
  }

  return obj
}

function strapiImageUrl (imgObj) {
  if (!imgObj) {
    return null
  }

  if ('data' in imgObj) {
    return strapiImageUrl(imgObj.data)
  }

  if (isArray(imgObj)) {
    if (imgObj.length === 1) {
      return strapiImageUrl(flattenStrapiObj(imgObj[0]))
    } else {
      return imgObj.map(item => strapiImageUrl(flattenStrapiObj(item)))
    }
  } else {
    if (imgObj.url) {
      return `${API_URL}${imgObj.url}?fm=webp`
    }

    return null
  }
}

export function convertLikeList (listOfLikes) {
  if (listOfLikes && listOfLikes.length > 0) {
    if (listOfLikes[0].app) {
      return listOfLikes.map(app => convertAppLike(app))
    } else {
      return listOfLikes.map(service => convertServiceLike(service))
    }
  }
  return []
}

export function convertAppLike (like) {
  const subcategories = []
  if (like.app.subcategories) {
    like.app.subcategories.forEach(function (subcategory) {
      subcategories.push({
        url: `${API_URL}/subcategories/${subcategory.slug}/`,
        name: subcategory.name,
      })
    })
  }

  return {
    id: like.id,
    contentType: 'app_like',
    url: `${API_URL}/likes/apps/${like.id}/`,
    listing: {
      id: like.app.id,
      contentType: 'app',
      url: `${SITE_URL}/profile/app/${like.app.slug}`,
      name: like.app.name,
      logo: (like.app.logo && (like.app.logo.length > 0))
        ? `${API_URL}${like.app.logo[0].url}`
        : undefined,
      subcategories: subcategories,
    },
    created_by: like.created_by,
  }
}

export function convertServiceLike (like) {
  return {
    id: like.id,
    contentType: 'service_like',
    url: `${API_URL}/likes/services/${like.id}/`,
    listing: {
      id: like.service.id,
      contentType: 'service',
      url: `${SITE_URL}/profile/cloud-expert/${like.service.slug}`,
      name: like.service.name,
      logo: (like.service.logo && (like.service.logo.length > 0))
        ? `${API_URL}${like.service.logo[0].url}`
        : undefined,
    },
    created_by: like.created_by,
  }
}

export function convertReview (type, review) {
  review = flattenStrapiObj(review)
  const returnObj = {
    id: review.id,
  }

  if (review.rating) {
    returnObj.rating = review.rating
  }

  if (review.comment) {
    returnObj.comment = review.comment
  }

  if (review.note) {
    returnObj.note = review.note
  }

  if (review.account) {
    returnObj.created_by = review.account.id
    returnObj.name = review.account.first_name
  }

  if (review.slug) {
    returnObj.url = `${SITE_URL}${review.slug}/`
  } else {
    returnObj.url = `${SITE_URL}/reviews/${type}/${review.id}/`
  }

  returnObj.contentType = 'review'

  return returnObj
}

export function convertPostReview (type, review) {
  review = flattenStrapiObj(review)
  return {
    id: review.id,
    contentType: 'review',
    url: `${SITE_URL}/reviews/${type}/${review.id}/`,
    created_by: review.createdBy,
    rating: review.rating,
    note: review.comment,
    comment: review.comment,
  }
}

export function convertSubcategory (subcategory) {
  const apps = []
  if (subcategory.attributes.apps) {
    convertAppArray(subcategory.attributes.apps.data)
      .forEach(app => apps.push(app))
  }

  let categoryUrl = ''
  if (subcategory.attributes.category && subcategory.attributes.category.data &&
    subcategory.attributes.category.data[0]) {
    categoryUrl = `${SITE_URL}/categories/${subcategory.attributes.category.data[0].attributes.slug}`
  }

  return {
    id: subcategory.id,
    contentType: 'subcategory',
    slug: subcategory.attributes.slug,
    url: `${SITE_URL}/subcategories/${subcategory.attributes.slug}/`,
    name: subcategory.attributes.name,
    category: categoryUrl,
    apps: apps,
    total_app_count: apps.length,
  }
}

export function convertCategory (category, subCategories = []) {
  const services = []
  if (category.attributes.services) {
    category.attributes.services.data.forEach(function (service) {
      try {
        services.push(convertService(service))
      } catch (e) {
        console.error('Category - service error')
      }
    })
  }

  if (category.attributes.subcategories) {
    const newSubCategories = []
    category.attributes.subcategories.data
      .forEach(function (subcategory) {
        try {
          newSubCategories.push(convertSubcategory(subcategory))
        } catch (e) {
          console.error('Category - subcategory error')
        }
      })

    subCategories = newSubCategories
  }

  return {
    id: category.id,
    contentType: 'category',
    url: category.attributes.url ??
      `${SITE_URL}/tools/${category.attributes.slug}`,
    name: category.attributes.name,
    subcategories: subCategories,
    services: services,
  }
}

export function convertService (service) {
  const industries = []

  if (service.attributes.industries) {
    flattenStrapiObj(service.attributes.industries)
      .forEach(industry => {
        industries.push({
          id: industry.id,
          url: `${SITE_URL}/industries/${industry.slug}/`,
          name: industry.name,
        })
      })
  }

  let categories = []
  if (service.attributes.categories) {
    if ('mini' in service.attributes.categories) {
      categories = service.attributes.categories.data.map(
        (category) => `${API_URL}/categories/${category.slug}`,
      )
    } else {
      categories = service.attributes.categories.data.map(
        (category) => `${API_URL}/categories/${category.attributes.slug}`,
      )
    }
  }

  const apps = []
  if (service.attributes.apps) {
    convertAppArray(service.attributes.apps.data)
      .forEach(app => apps.push(app))
  }

  let locations = []
  if (service.attributes.locations) {
    if ('mini' in service.attributes.locations) {
      locations = service.attributes.locations.data.map(
        (location) => ({
          id: location.id,
          url: `${API_URL}/locations/${location.slug}/`,
          name: location.name,
        }),
      )
    } else {
      locations = service.attributes.locations.data.map(
        (location) => ({
          id: location.id,
          url: `${API_URL}/locations/${location.attributes.slug}/`,
          name: location.attributes.name,
        }),
      )
    }
  }

  let numberOfReview = 0
  const reviews = []
  if (service.attributes.reviews) {
    service.attributes.reviews.data.forEach(function (review) {
      reviews.push(convertReview('services', review))
    })

    if (service.attributes.rating_raw) {
      numberOfReview = service.attributes.rating_raw.total
    }
  }

  const teamMembers = []
  if (service.attributes.team_members && service.attributes.team_members.data) {
    service.attributes.team_members.data.forEach((teamMember) => {
      if (teamMember && teamMember.attributes) {
        teamMembers.push({
          id: teamMember.id,
          photo: strapiImageUrl(teamMember.attributes.photo),
          name: teamMember.attributes.name,
          position: teamMember.attributes.position,
          description: teamMember.attributes.description,
        })
      }
    })
  }

  return {
    id: service.id,
    contentType: 'service',
    url: `${SITE_URL}/profile/cloud-expert/${service.attributes.slug}`,
    created_at: service.attributes.createdAt,
    created_by: service.attributes.email,
    name: service.attributes.name,
    slug: service.attributes.slug,
    logo: strapiImageUrl(service.attributes.logo),
    rating: service.attributes.rating,
    free_trial: service.attributes.trial ?? '',
    short_description: service.attributes.summary,
    long_description: service.attributes.description,
    email: service.attributes.email,
    phone: service.attributes.phone,
    link: service.attributes.link,
    pricing: service.attributes.pricing,
    num_reviews: numberOfReview,
    featured: service.attributes.featured,
    locations: locations,
    nz_owned: service.attributes.nz,
    categories: categories,
    apps: apps,
    industries: industries,
    special_offers: [],
    pending_special_offers: [],
    reviews: reviews,
    team: teamMembers,
  }
}

const convertRelation = async (
  parentObj, relationKey, convertFunc, relationCollection = '') => {
  if (parentObj[relationKey]) {
    if (Array.isArray(parentObj[relationKey]) && convertFunc) {
      // Handle with a converter
      return parentObj[relationKey].map(item => convertFunc(item))
    } else {
      if ('mini' in parentObj[relationKey]) {
        // Either lookup or return
        if (relationCollection) {
          // lookup
          const ids = parentObj[relationKey].data.flatMap(item => item.id)
          if (ids && ids.length > 0) {
            return await getEntriesById(ids, relationCollection)
              .then(response => {
                if (response && response.data) {
                  return response.data
                }
                return []
              })
              .then(async (data) => {
                const converted = []
                for (const item of data) {
                  try {
                    const i = await convertFunc(item)
                    if (i) {
                      converted.push(i)
                    }
                  } catch (error) {
                    console.error(error)
                  }
                }
                return converted
              })
          }

          return []
        } else {
          return parentObj[relationKey].data
        }
      } else if (parentObj[relationKey].data && convertFunc) {
        // Handle with the converter
        return parentObj[relationKey].data.map(item => convertFunc(item))
      }
    }
  }

  return []
}

export async function convertIndustry (industry, { fetchFeatured } = null) {
  if (!industry) return null
  industry = flattenStrapiObj(industry)

  const services = await convertRelation(industry, 'services', convertService)
  const apps = await convertRelation(industry, 'apps', convertApp)

  const featuredServices = await convertRelation(industry, 'featuredServices',
    convertService, fetchFeatured ? 'services' : null)
  const featuredApps = await convertRelation(industry, 'featuredApps',
    convertApp,
    fetchFeatured ? 'apps' : null)

  return {
    id: industry.id,
    contentType: 'industry',
    slug: industry.slug,
    url: `${SITE_URL}/industries/${industry.slug}/`,
    name: industry.name,
    services: services,
    description: industry.description,
    apps: apps,
    featured_apps: featuredApps,
    featured_services: featuredServices,
  }
}

// Takes an array of apps and returns the approved ones
export function convertAppArray (apps) {
  return apps
    .filter(app => app.attributes.status === 'APPROVED')
    .map(app => convertApp(app))
}

export function convertApp (app) {
  let industries = []

  app = flattenStrapiObj(app)
  if (app.status !== 'APPROVED') {
    return
  }

  if (app.industries) {
    industries = convertIndustriesList(flattenStrapiObj(app.industries))
  }

  let subcategories = []
  if (app.subcategories) {
    flattenStrapiObj(app.subcategories)
      .forEach(function (subCategory) {
        subcategories.push({
          id: subCategory.id,
          slug: subCategory.slug,
          url: `${SITE_URL}/subcategories/${subCategory.slug}/`,
          name: subCategory.name,
        })
      })
  }

  let reviews = []
  if (app.reviews) {
    flattenStrapiObj(app.reviews)
      .filter(review => review.status === 'APPROVED')
      .forEach(function (review) {
        reviews.push(convertReview('apps', review))
      })
  }

  let numberOfReview = app.reviews ? app.reviews.length : 0
  if (!numberOfReview && app.rating_raw) {
    numberOfReview = app.rating_raw.total
  }

  const result = {
    id: app.id,
    contentType: 'app',
    url: `${SITE_URL}/profile/app/${app.slug}`,
    rating: app.rating ?? null,
    upgraded: false,
    subcategories: subcategories,
    reviews: reviews,
    special_offers: [],
    pending_special_offers: [],
    created_at: app.created_at,

    name: app.name,
    slug: app.slug,
    logo: strapiImageUrl(app.logo),

    short_description: app.summary,
    long_description: app.description,
    link: app.link,
    free_trial: app.trial ?? null,

    pricing: app.pricing,
    nz_owned: app.nz ?? false,

    // Not in the original api request
    num_reviews: numberOfReview,
    featured: app.featured,
    created_by: app.account,
  }

  if (industries.length > 0) {
    result.industries = industries
  }

  return result
}

export function convertServicesList (services) {
  const serviceList = []

  services.forEach(function (service) {
    if (service && service.attributes && service.attributes.status ===
      'APPROVED') {
      serviceList.push(convertService(service))
    }
  })

  return serviceList
}

export function convertAppLikes (likes) {
  const appLikes = []

  if (likes) {
    likes.forEach(function (appLike) {
      appLikes.push(convertAppLike(appLike))
    })
  }

  return appLikes
}

export function convertServiceLikes (likes) {
  const serviceLikes = []

  if (likes) {
    likes.forEach(function (serviceLike) {
      serviceLikes.push(convertServiceLike(serviceLike))
    })
  }

  return serviceLikes
}

export function convertIndustriesList (industries) {
  const industryList = []

  industries.forEach(function (industry) {
    if (industry.attributes) {
      industryList.push({
        id: industry.id,
        contentType: 'industry',
        slug: industry.attributes.slug,
        url: `${API_URL}/industries/${industry.attributes.slug}/`,
        site_url: `${SITE_URL}/directory/cloud-experts/${industry.attributes.slug}/`,
        name: industry.attributes.name,
      })
    } else if (industry.name) {
      industryList.push({
        id: industry.id,
        contentType: 'industry',
        slug: industry.slug,
        url: `${API_URL}/industries/${industry.slug}/`,
        site_url: `${SITE_URL}/directory/cloud-experts/${industry.slug}/`,
        name: industry.name,
      })
    }

  })

  return industryList
}

export function convertCategoriesList (categories) {
  const categoryList = []

  categories.forEach(function (category) {
    if (category.attributes) {
      categoryList.push({
        id: category.id,
        contentType: 'category',
        slug: category.attributes.slug,
        url: `${API_URL}/categories/${category.attributes.slug}/`,
        name: category.attributes.name,
      })
    } else if (category.name) {
      categoryList.push({
        id: category.id,
        contentType: 'category',
        slug: category.slug,
        url: `${API_URL}/categories/${category.slug}/`,
        name: category.name,
      })
    }

  })

  return categoryList
}

export function convertSubCategoriesList (subCategories, limitApps = 0) {
  const subCategoryList = []

  flattenStrapiObj(subCategories).forEach(function (subCategory) {
    let apps = convertAppArray(subCategory.apps.data) ?? []
    const appCount = apps.length
    if (limitApps && limitApps < apps.length) {
      apps = apps.slice(0, limitApps)
    }

    subCategoryList.push({
      id: subCategory.id,
      contentType: 'subcategory',
      slug: subCategory.slug,
      url: `${SITE_URL}/subcategories/${subCategory.slug}/`,
      name: subCategory.name,
      category: `${SITE_URL}/categories/${subCategory.category.data.attributes.slug}/`,
      apps,
      total_app_count: appCount,
    })
  })

  return subCategoryList
}

export function convertServiceArray (strapiServiceList) {
  let serviceList = []
  if (strapiServiceList && Array.isArray(strapiServiceList)) {
    serviceList = strapiServiceList.map(service => convertService(service))
  }

  return serviceList
}