import { ListingType } from "../../types/listing";
import Cookies from "../../services/Cookies";
import { JSX, useEffect, useState } from "react";
import { ADMIN_URL, API_URL } from "../../constraints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faSolidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faOutlineHeart } from "@fortawesome/free-regular-svg-icons";
import "./WishlistButton.scss"

type WishlistButtonProps = {
    listingId: number
    listingType: ListingType
}
export default function WishlistButton({listingId, listingType}: WishlistButtonProps): JSX.Element {
    const token = Cookies.get('AUTH_TOKEN')
    const [onWishlist, setOnWishlist] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [likeId, setLikeId] = useState<number>()

    const fetchOpts = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
        }
    }

    const checkLike = async () => {
        if (listingId && listingType && token) {
            fetch(`${API_URL}/api/likes/${listingType}/${listingId}`, fetchOpts)
                .then(res => res.json())
                .then(response => {
                    if (response && response.data && response.data.id) {
                        setLikeId(response.data.id)
                        setOnWishlist(true)
                    } else {
                        setOnWishlist(false)
                    }
                })
                .catch(() => setOnWishlist(false))
        }
    }


    useEffect(() => {
        checkLike();

        const interval = setInterval(checkLike, 30000);

        // Cleanup function to clear the interval when the component unmounts
        return () => {
            clearInterval(interval);
        };
    }, [token, listingId, listingType]);

    const handleClick = async () => {
        if (loading) {
            return
        }

        setLoading(true)

        // Check if signed in
        if (!token) {
            window.open(ADMIN_URL + `/auth/login?returnTo=${window.location.pathname}`)
            return
        }

        // Timeout
        const timeout = setTimeout(() => setLoading(false), 2000)
        let url = `${API_URL}/api/likes`
        const body = {
            method: "POST",
            headers: fetchOpts.headers,
            body: ''

        }

        if (onWishlist) {
            // Need to remove
            url += `/${likeId}`
            body.method = "DELETE"
        } else {
            body.body = JSON.stringify({
                data: {
                    [listingType]: listingId
                }
            })
        }

        fetch(url, body)
            .then(res => {
                if (res.status === 200) {
                    setOnWishlist(body.method === "POST")
                    return res.json()
                }
            })
            .then(response => {
                if (response && response.data && response.data.id && !onWishlist) {
                    setLikeId(response.data.id)
                }
            })
            .catch(err => console.error(err))
            .finally(() => {
                setLoading(false)
                clearTimeout(timeout)
            })
    }

    // if (!token) {
    //     return (<></>)
    // }

    return (
        <div className={"wishlist-button"}>
            <button>
                <FontAwesomeIcon icon={onWishlist ? faSolidHeart : faOutlineHeart}
                                 color={onWishlist ? 'red' : 'grey'}
                                 className={onWishlist ? "wishlist-button__icon" : "wishlist-button__icon outline"}
                                 onClick={handleClick}
                />
            </button>
        </div>
    )
}