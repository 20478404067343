import React, { Component } from 'react'
import './listing.scss'
import OfferToolTip from './offertooltip'
import ReactStars from 'react-rating-stars-component'
import noImage from '../../assets/images/noImage.png'
import ReactTooltip from 'react-tooltip'
import flag from '../../assets/images/flagsvg.png'
import Cookie from 'js-cookie'
import {
  faHeart,
  faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons'
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ADMIN_URL } from '../../constraints'

const token = Cookie.get('AUTH_TOKEN') ? Cookie.get('AUTH_TOKEN') : null

class Listing extends Component {
  clickedListing (e, link) {
    if (e.target.parentNode.classList.contains('hearticon')) {
    } else if (e.target.classList.contains('hearticon')) {
    } else {
      window.location.href = `${link}`
    }
  }

  render () {
    if (!this.props.data) {
      return (
        <></>
      )
    }
    let link = this.props.link ?? this.props.data.url

    if (this.props.data === undefined) {
      return null
      // <p className="listing_p">Hmmm looks like we could find any tools.</p>
    } else if (link.includes('/profile/cloud-expert')) {
      const addFunction = this.props.addFunction
      const name = this.props.data.name
      const apps = this.props.data.apps ? this.props.data.apps : []
      const offers = this.props.data.special_offers
      const location =
        this.props.data.locations[0] && this.props.data.locations[0].name
      const pricing =
        this.props.data.pricing === 'nan' ? null : this.props.data.pricing
      const stars = this.props.data.rating
      const logo = this.props.data.logo
      const shortdesc = this.props.data.short_description
      const num_reviews = this.props.data.num_reviews
      return (
        <div className="listing" onClick={(e) => this.clickedListing(e, link)}>
          <div className="listingLogo">
            <img
              className="m-hide"
              src={
                logo === null || logo === undefined || logo === ''
                  ? noImage
                  : logo
              }
              alt="The Listing logo"
            ></img>
          </div>
          <div className="listingDetail">
            <div className="listingWrapper">
              <div>
                {' '}
                <img
                  className={'d-hide t-hide'}
                  src={
                    logo === null || logo === undefined || logo === ''
                      ? noImage
                      : logo
                  }
                  alt="The Listing logo"
                ></img>
              </div>
              <div>
                <p className="listing_p m-hide">{location}</p>
                {token ? (
                  <div className="hearticonWrapper">
                    <FontAwesomeIcon
                      className={
                        token && this.props.wishlist
                          ? 'pointer hearticon inline-block  heartFilledYes'
                          : 'pointer heartOutline hearticon'
                      }
                      icon={this.props.wishlist ? faHeart : farHeart}
                      onClick={() => addFunction(this.props.data)}
                    />
                  </div>
                ) : (
                  <div className="hearticonWrapper">
                    <FontAwesomeIcon
                      className={'pointer heartOutline hearticon'}
                      icon={farHeart}
                      data-tip
                      data-for={name}
                    />
                    <ReactTooltip
                      id={name}
                      className="tooltip"
                      aria-haspopup="true"
                      effect="solid"
                    >
                      <span className="tooltiptitle">
                        Sign up to save this to your toolbox
                      </span>
                      <span>Click me to create an account</span>
                    </ReactTooltip>
                  </div>
                )}
                {offers.length > 0 && <OfferToolTip data={offers} />}

                <h4>{name}</h4>
              </div>
            </div>

            <div className="starssidebyside">
              <ReactStars
                value={stars ? stars : 0}
                size={30}
                edit={false}
                activeColor={'#FAAA00'}
                color={'#767676'}
              />
              <span className="darkgrey"> - {num_reviews} Reviews</span>
            </div>
            <p className="listing_p">{shortdesc}</p>
            <p className="listing_p pricing_p">{pricing}</p>
            <div className="app_contaienr">
              {apps.map((app, i) => {
                if (app.logo) {
                  if (i > 3) {
                    return null
                  }
                  return (
                    <img
                      alt="The featured apps logo"
                      className="featuredapplogo"
                      src={app.logo}
                    />
                  )
                } else {
                  return null
                }
              })}
            </div>
            <a href={link}>
              <span className="blue">
                Contact Provider &nbsp;
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </span>
            </a>
          </div>
          <div className={this.props.data.nz_owned ? 'flag' : 'hide'}>
            {' '}
            <img src={flag} />
          </div>
        </div>
      )
    } else {
      const name = this.props.data.name
      const industry = this.props.data.subcategories
      const pricing =
        this.props.data.pricing === 'nan' ? null : this.props.data.pricing
      const stars = this.props.data.rating
      let logo = this.props.data.logo
      const offers = this.props.data.special_offers
      const shortdesc = this.props.data.short_description
      const link = this.props.link
      const num_reviews = this.props.data.num_reviews
      var addFunction = this.props.addFunction
      return (
        <div className="listing" onClick={(e) => this.clickedListing(e, link)}>
          <div className="listingLogo">
            <img
              className="m-hide"
              src={
                logo === null || logo === undefined || logo === ''
                  ? noImage
                  : logo
              }
              alt="The Listing logo"
            ></img>
          </div>
          <div className="listingDetail">
            <div className="listingWrapper">
              <div>
                {' '}
                <img
                  className={'d-hide t-hide'}
                  src={
                    logo === null || logo === undefined || logo === ''
                      ? noImage
                      : logo
                  }
                  alt="logo"
                ></img>
              </div>

              <div>
                {' '}
                <p className="listing_p m-hide">
                  {industry.map((data, i) => {
                    let length = industry.length
                    if (length === i + 1) {
                      return `${data.name}`
                    } else {
                      return `${data.name}, `
                    }
                  })}
                </p>
                <h4>{name}</h4>
              </div>
              <div className="hearticonWrapper">
                {token ? (
                  <FontAwesomeIcon
                    className={
                      token && this.props.wishlist
                        ? 'pointer hearticon inline-block  heartFilledYes hearticon'
                        : 'pointer heartOutline hearticon'
                    }
                    icon={this.props.wishlist ? faHeart : farHeart}
                    onClick={() => addFunction(this.props.data)}
                  />
                ) : (
                  <div className="hearticonWrapper m-hide">
                    <a href={ADMIN_URL +
                      '/auth/registration'}>
                      <FontAwesomeIcon
                        className={'pointer heartOutline hearticon'}
                        icon={farHeart}
                        data-tip
                        data-for={name}
                      />

                      <ReactTooltip
                        id={name}
                        className="tooltip"
                        aria-haspopup="true"
                        effect="solid"
                      >
                        <span className="tooltiptitle">
                          Sign up to save this to your toolbox
                        </span>
                        <span>Click me to create an account</span>
                      </ReactTooltip>
                    </a>
                  </div>
                )}
                {/* {offers.length > 0 && <OfferToolTip data={offers} />} */}
              </div>
            </div>

            <div className="starssidebyside">
              {' '}
              <ReactStars
                value={stars ? stars : 0}
                size={30}
                edit={false}
                activeColor={'#FAAA00'}
                color={'#767676'}
              />{' '}
              <span className="darkgrey">{num_reviews} Reviews</span>
            </div>
            <p className="listing_p">{shortdesc}</p>
            <p className="listing_p pricing_p">{pricing}</p>
            <a href={link}>
              <span className="blue">
                Learn More &nbsp;
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </span>
            </a>
          </div>
          <div className={this.props.data.nz_owned ? 'flag' : 'hide'}>
            {' '}
            <img src={flag} alt="new zealand flag" />
          </div>
        </div>
      )
    }
  }
}

export default Listing
