import React, { useEffect, useState } from "react";
import strapi from "../services/Strapi";
import { sample } from "lodash-es"
import { convertCategory } from "../util/api_converters";
import SmallCTA from "../components/small_cta/small_cta";

export default function ToolsCTA() {
    const [url, setUrl] = useState()

    useEffect(() => {
        // Get 1 random category
        strapi.get('/categories')
            .json(result => {
                if (result && result.data) {
                    const randomCategory = convertCategory(sample(result.data));
                    if (randomCategory) {
                        setUrl(randomCategory.url)
                    }
                }
            });
    }, [])

    if (!url) {
        return (
            <></>
        )
    }

    return (
        <SmallCTA
            sub={'Looking for best of breed software for your business?'}
            button_text={'Check out the app directory'}
            button_link={url}
        />
    )
}