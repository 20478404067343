import React, { Component } from 'react'
import FilterMainOld from '../components/filter_main/filtermain_old'
import { Helmet } from 'react-helmet'
import { SITE_URL } from '../constraints'
import ToolsCTA from '../content/ToolsCTA'
import { Widget } from '@typeform/embed-react'

class ToolFinder extends Component {
  render () {
    return (
      <div id="site_page">
        <Helmet>
          <meta
            name="description"
            content=" Quickly find out what tools you should use for your business. Try the Free Tool Finder free. "
          />
          <meta
            property="og:image"
            content={`${SITE_URL}/TheRightTool.jpg`}
          />
          <title>
            Free Tool Finder: Let us suggest The Right Tool for your business
          </title>
        </Helmet>
        <FilterMainOld search={false} text={'Free Tool Finder'} />
        <div id="OurStory" className="gc  center_all content">
          <div className="d-2-12 t-3-11 m-2-12 ">
            <Widget id={'aUQydUOu'} className="typeform-widget"
                    style={{ width: '100%', height: '650px' }} />
          </div>
        </div>
        <ToolsCTA />
      </div>
    )
  }
}

export default ToolFinder
