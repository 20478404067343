import { Fragment, JSX } from "react";
import "./HomeCard.scss"

export type HomeCardProps = {
    title: string | string[]
    titleRed?: number[]
    copy?: string

    mainImg: string
    mainImgAlt?: string
    logo?: string
    logoAlt?: string

    href?: string
    label?: string
}

export default function HomeCard(props: HomeCardProps): JSX.Element {

    const generateHtmlTitle = () => {
        if (Array.isArray(props.title)) {
            if (props.titleRed && props.titleRed.length > 0) {
                return (
                    <h4 className={"home-card__title"}>
                        {props.title.map((text, index) => {
                            if (props.titleRed?.includes(index)) {
                                return <span key={index}>{text}</span>
                            }
                            return <Fragment key={index}> {text} </Fragment>
                        })}
                    </h4>
                )
            }
            return <h4 className={"home-card__title"}>{props.title.join(' ')}</h4>
        }

        return <h4 className={"home-card__title"}>{props.title}</h4>
    }
    const generateTextTitle = () => {
        if (Array.isArray(props.title)) {
            return props.title.join(' ')
        }
        return props.title
    }

    const handleClick = () => {
        if (props.href && window.location.href !== props.href) {
            window.location.href = props.href
        }
    }

    return (
        <div className={"home-card--regular"} onClick={handleClick}>
            <img src={props.mainImg}
                 alt={props.mainImgAlt || generateTextTitle()}
                 style={{objectFit: 'cover'}}
            />

            <div className={"home-card__content"}>
                <div>
                    <div>
                        {props.logo && (
                            <div className={"home-card__logo"}>
                                <img src={props.logo}
                                     alt={props.logoAlt || generateTextTitle()}
                                />
                            </div>
                        )}
                    </div>

                    {generateHtmlTitle()}

                    <p className={"home-card__body"}>
                        {props.copy}
                    </p>
                    {props.href && (
                        <div className={"home-card__button"}>
                            <a href={props.href}>
                                <span>
                                    {props.label || "Learn more"}
                                </span>
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}