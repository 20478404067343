import React, { Component } from 'react'
import FilterMainOld from '../components/filter_main/filtermain_old'
import Button from '../components/button/button'
import { Helmet } from 'react-helmet'
import { ADMIN_URL } from '../constraints'
import Cookies from '../services/Cookies'

function About (props) {
  const token = Cookies.get('AUTH_TOKEN')

  const ctaLink = token
    ? `${ADMIN_URL}/apps/new?user=me`
    : `${ADMIN_URL}/auth/register`

  return (
    <div className="gc no-gap center_all content bubbles">
      <div className="d-4-10 t-3-11 m-2-12 text_center">
        <h2 className="padding_h2">Why list on The Right Tool™</h2>
        <p className="main_p">
          Your listing will give you the opportunity to acquire high buying
          intent web traffic and sales leads from small business owners ready to
          try and buy your solution.
          <br></br>
          <br></br>
          Use The Right Tool™ as a place to collect reviews for your product and
          to build and nurture your community.
          <br></br>
          <br></br>
          Tailored categories specific to business lifestage and industry.
        </p>
      </div>
      <div className="d-4-10 t-3-11 m-1-13 text_center buffer">
        <Button
          classname="signuptag"
          text="Create your free listing"
          color="blue"
          link={ctaLink}
        />
      </div>
    </div>
  )
}

class listapp extends Component {
  render () {
    return (
      <div id="site_page">
        <Helmet>
          <title> List your app for free on The Right Tool</title>
          <meta
            name="description"
            content="Your listing will give you the opportunity to acquire high buying intent web traffic and sales leads from small business owners ready to try and buy your solution."
          />
        </Helmet>
        <FilterMainOld
          search={false}
          text={
            'Create a free listing for your business software, app or product'
          }
          bottom="List for free on The Right Tool™ Aotearoa’s app directory, helping connect small business owners with the world’s leading business software."
        />
        <About />
      </div>
    )
  }
}

export default listapp
