import React, { Component } from 'react'
import './menu.scss'
import { withRouter } from 'react-router-dom'
import logowhite from '../../assets/images/logo_white.png'
import logoblue from '../../assets/images/logo_blue.png'
import searchIconClose from '../../assets/images/searchIconClose.png'
import searchIconBlack from '../../assets/images/search_icon.png'
import searchIconWhite from '../../assets/images/search_icon_white.png'
import Filter from '../../components/filter/filter'
import Search from '../search/search'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { categories } from '../../util/service'
import { ADMIN_URL } from '../../constraints'
import Cookies from '../../services/Cookies'
import { getCategoryOrIndustry } from '../../helpers/tools'
import { WishlistMenu } from '../wishlist/WishlistMenu'

const token = Cookies.get('AUTH_TOKEN')

class SearchOverlay extends Component {
  componentDidUpdate () {
    if (this.props.visble) {
      document.getElementById('searchInputField').focus()
    }
  }

  render () {
    const isvisible = this.props.visble

    return (
      <div
        className={`SearchOverlay ${
          isvisible ? 'fadeinsearch' : 'fadeoutsearch'
        }`}
      >
        <Search />
      </div>
    )
  }
}

class menu extends Component {
  constructor (props) {
    super(props)

    this.state = {
      // hideMenu: false,
      open: false,
      initialData: [],
      searchIsOpen: false,
      category: [],
    }
    this.setFilter = this.setFilter.bind(this)
  }

  handleClickMenuMobile = () => {
    this.setState({
      open: (this.open = !this.open),
    })
  }

  handleOpenSearch = () => {
    document.getElementsByTagName('body')[0].classList.toggle('overflowy')

    this.setState({
      searchIsOpen: (this.searchIsOpen = !this.searchIsOpen),
    })
  }

  scrollListener = () => {
    let elementTarget = 100
    if (window.scrollY > elementTarget) {
      this.setState({ wishlist_open: false, open: false })
    }
  }

  componentDidMount () {
    window.addEventListener('scroll', this.scrollListener)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.scrollListener)
  }

  setFilter (arg) {
    // This is the main filter on the menu that is for categories
    const filters = getCategoryOrIndustry()

    // If the current filter does not match the arg, we need to navigate
    if (filters.category && filters.category !== arg) {
      window.location.pathname = `/tools/${arg}`
    }
  }

  render () {
    const { open } = this.state
    const { transparent } = this.props

    const homeLogo = transparent ? logowhite : logoblue
    const searchIcon = transparent ? searchIconWhite : searchIconBlack

    if (token) {
      return (
        <div className={'menu'}>
          <div
            id="menu"
            className={`gc no-gap ${transparent
              ? ''
              : 'menu_background_white'}`}
          >
            <div className="d-2-2 t-2-2 m-3-5 headerbar-container aboveMenu">
              <a href="/" title="return to the homepage">
                <img
                  className="header-logo"
                  src={!this.state.searchIsOpen ? homeLogo : logoblue}
                  alt="The Right Tool Logo"
                ></img>
              </a>
              {window.location.href.indexOf('tools') > -1 ? (
                <div className="m-hide">
                  <Filter
                    id="main_categoryDrop"
                    idlist="main_categoryDropList"
                    query={categories}
                    currentIndustry={this.state.category}
                    setFilter={this.setFilter}
                  />
                </div>
              ) : (
                ''
              )}
            </div>

            <div
              className={`d-5-12 t-5-12 m-hide headerbar-container header-signup`}
            >
              <div className={`pointer headerbar-container`}>
                <WishlistMenu />

                <div className="header-actions">
                  <a href={ADMIN_URL}>
                    <span>Account</span>
                  </a>
                </div>

                <div className="header-actions">
                  <a href={ADMIN_URL + '/logout'}>
                    <span>Sign Out</span>
                  </a>
                </div>
                <div className="header-actions header-signup">
                  <a className="signuptag" href="/free-tool-finder">
                    <span>Free Tool Finder</span>
                  </a>
                </div>
                <div className="header-actions aboveMenu">
                  <img
                    onClick={() => this.handleOpenSearch()}
                    src={!this.state.searchIsOpen
                      ? searchIcon
                      : searchIconClose}
                    className="searchIcon inline-block"
                    alt={''} />
                </div>
              </div>
            </div>

            <SearchOverlay visble={this.state.searchIsOpen} />


            <div
              className={`mobile-menu m-8-8 headerbar-container header-signup`}
            >
              <div className={`pointer headerbar-container`}>
                <WishlistMenu />
              </div>
            </div>

            <div className={`mobile-menu m-9-12  headerbar-container `}>
              <div className={'headerbar-container'}>
                <div
                  className={`pointer headerbar-container`}
                  onClick={this.handleClickMenuMobile}
                >
                  <FontAwesomeIcon
                    className={'heartFilledBlack'}
                    icon={open ? faTimes : faBars}
                  />
                </div>

                <div
                  id="landingDropdownLoggedin"
                  className={`menuppopupmodal ${
                    open ? 'active' : 'inactiveMenu'
                  }`}
                >
                  <a href="/free-tool-finders">
                    <div className="header-actions">
                      <span>Free Tool Finder</span>
                    </div>
                  </a>
                  <a href={`${ADMIN_URL}`}>
                    <div className="header-actions">
                      <span>Account</span>
                    </div>
                  </a>
                  <a href={ADMIN_URL + '/logout'}>
                    <div className="header-actions">
                      <span>Sign Out</span>
                    </div>
                  </a>
                </div>
                <div className="header-actions aboveMenu">
                  <img
                    onClick={() => this.handleOpenSearch()}
                    src={!this.state.searchIsOpen
                      ? searchIcon
                      : searchIconClose}
                    className="searchIcon inline-block"
                    alt={''} />
                </div>
              </div>
            </div>

            <a className="skiplink" href="#footer">
              Skip To Footer
            </a>
          </div>
        </div>
      )
    } else {
      return (
        <div className={'menu'}>
          <div
            id="menu"
            className={`gc no-gap ${transparent
              ? ''
              : 'menu_background_white'}`}
          >
            <a className="skiplink" href="#footer">
              Skip to Footer
            </a>
            <div className="d-2-3 m-3-5 headerbar-container aboveMenu">
              <a href="/" title="return to the homepage">
                <img
                  className="header-logo"
                  src={!this.state.searchIsOpen ? homeLogo : logoblue}
                  alt="The Right Tool logo"
                ></img>
              </a>
              {window.location.href.indexOf('tools') > -1 ? (
                <div className="m-hide">
                  <Filter
                    id="main_categoryDrop"
                    idlist="main_categoryDropList"
                    query={categories}
                    currentIndustry={this.state.category}
                    setFilter={this.setFilter}
                  />
                </div>
              ) : (
                ''
              )}
            </div>

            <div
              className={`d-4-12 t-4-12 m-hide headerbar-container header-signup`}
            >
              <div className={`pointer headerbar-container`}>
                <div className="header-actions">
                  <a href={ADMIN_URL + '/auth/login'}>
                    <span>Sign In</span>
                  </a>
                </div>
                <div className="header-actions">
                  <a
                    href={ADMIN_URL + '/auth/registration'}>
                    <span>Create Account</span>
                  </a>
                </div>
                <div className="header-actions header-signup">
                  <a className="signuptag" href="/free-tool-finder">
                    <span>Free Tool Finder</span>
                  </a>
                </div>
                <div className="header-actions aboveMenu">
                  <img
                    onClick={() => this.handleOpenSearch()}
                    src={!this.state.searchIsOpen
                      ? searchIcon
                      : searchIconClose}
                    className="searchIcon inline-block"
                    alt={''} />
                </div>
              </div>
            </div>

            <SearchOverlay visble={this.state.searchIsOpen} />

            <div
              className={`mobile-menu m-6-9 headerbar-container header-signup`}
            >
              <div className={`pointer headerbar-container`}>
                <div className="header-actions">
                  <a href={ADMIN_URL + '/auth/login'}>
                    <span>Sign In</span>
                  </a>
                </div>
              </div>
            </div>
            <div className={`mobile-menu m-9-12  headerbar-container `}>
              <div className={'headerbar-container'}>
                <div
                  className={`pointer headerbar-container`}
                  onClick={this.handleClickMenuMobile}
                >
                  <FontAwesomeIcon
                    className={
                      transparent ? 'heartFilledWhite' : 'heartFilledBlack'
                    }
                    icon={open ? faTimes : faBars}
                  />
                </div>
                <div className="header-actions aboveMenu">
                  <img
                    onClick={() => this.handleOpenSearch()}
                    src={!this.state.searchIsOpen
                      ? searchIcon
                      : searchIconClose}
                    className="searchIcon inline-block"
                    alt={''} />
                </div>
                <div
                  id="landingDropdownLoggedin"
                  className={`menuppopupmodal ${
                    open ? 'active' : 'inactiveMenu'
                  }`}
                >
                  <a
                    className="signuptag"
                    href={ADMIN_URL + '/auth/registration'}
                  >
                    <div className="header-actions">
                      <span>Create Account</span>
                    </div>
                  </a>
                  <a href="/free-tool-finder">
                    <div className="header-actions">
                      <span>Free Tool Finder</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default withRouter(menu)
