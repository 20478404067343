import "./ResultItem.scss"
import noImage from '../../assets/images/noImage.png'
import ReactStars from 'react-rating-stars-component'
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";

export type ResultItemProps = {
    id: number,
    contentType: string,
    name: string
    logo: string
    locations?: Record<string, string>[]
    subcategories?: Record<string, string>[]
    rating?: number
    num_reviews?: number
    url: string
}

export default function ResultItem(props: ResultItemProps): React.JSX.Element {
    const getLocationOrSubcategoryName = () => {
        if (props.locations && props.locations.length > 0 && props.locations[0].name) {
            return props.locations[0].name
        }

        if (props.subcategories && props.subcategories.length > 0 && props.subcategories[0].name) {
            return props.subcategories[0].name
        }

        return ''
    }

    return (
        <div className={"search-result-item"} onClick={() => window.location.href = props.url}>
            <div className={"search-result-item__inner-wrapper"}>
                <div className={"search-result-item__logo"}>
                    <img src={props.logo ?? noImage} alt={props.name} />
                </div>

                <div className={"search-result-item__info"}>
                    <div>
                        {/* Listing type and info */}
                        <span className={"listingType"}>
                            {props.contentType === 'app' ? 'Product' : 'Cloud Expert'}
                        </span>

                        <span className={"listingSubLabel"}>{getLocationOrSubcategoryName()}</span>
                    </div>

                    <div>
                        <span className={"listingTitle"}>{props.name}</span>
                    </div>

                    <div className="listingReview">
                        <ReactStars
                            value={props.rating}
                            size={20}
                            edit={false}
                            activeColor={'#FAAA00'}
                            color={'#767676'}
                        />
                        <span>
                          {`${props.num_reviews}  `} Reviews
                        </span>
                    </div>
                </div>
            </div>

            <div className={"search-result-item__inner-wrapper"}>
                <div className={"search-result-item__arrow"}>
                    <a href={props.url}>
                        <FontAwesomeIcon icon={faLongArrowAltRight} />
                    </a>
                </div>
            </div>
        </div>
    )
}