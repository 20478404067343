import React, { Component } from 'react'
import ReactStars from 'react-rating-stars-component'
import Button from '../components/button/button'
import CTA from '../components/cta/cta'
import Offer from '../components/offer/offer'
import noImage from '../assets/images/noImage.png'
import ServiceCta from '../components/service_cta/service_cta'
import ReactModal from 'react-modal'
import {
  faHeart,
  faLongArrowAltLeft,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cookie from 'js-cookie'
import { Helmet } from 'react-helmet'
import {
  convertApp,
  convertAppLikes,
  convertPostReview,
} from '../util/api_converters'
import { API_URL } from '../constraints'
import { ReviewContainer } from '../components/reviews/review_container'

const token = Cookie.get('AUTH_TOKEN') ? Cookie.get('AUTH_TOKEN') : null

class profileapps extends Component {
  constructor (props) {
    super(props)
    this.state = {
      industries: [],
      data: [],
      reviews: [],
      industries_served: [],
      showModal: false,
      review_rating: 0,
      loading: true,
      sendingReview: false,
      sendingClaim: false,
      claimSent: false,
      admin: false,
      reviewSent: false,
      app_likes: [],
      wishlistAdded: false,
      claimShowModal: false,
      review_content: '',
      url: '',
      errorsreview: [],
      name: props.match.params.handle,
    }
    this.handleFormChange = this.handleFormChange.bind(this)
    this.handleClaimOpenModal = this.handleClaimOpenModal.bind(this)
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleClaimCloseModal = this.handleClaimCloseModal.bind(this)
    this.fetchListing = this.fetchListing.bind(this)
  }

  fetcher () {
    return fetch(API_URL + '/api/likes/apps/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        const likesData = convertAppLikes(responseData)
        this.setState({ app_likes: likesData })
        for (let i = 0; i < likesData.length; i++) {
          const element = likesData[i]
          if (element.listing.url === this.state.data.url) {
            this.setState({ wishlistAdded: true, url: element.url })
          }
        }
      })
      .catch((error) => {
        this.setState({ error: error })
        console.error(this.state.error)
      })
  }

  handleClaimOpenModal () {
    this.setState({ claimShowModal: true })
  }

  handleClaimCloseModal () {
    this.setState({ claimShowModal: false })
  }

  handleOpenModal () {
    this.setState({ showModal: true })
  }

  handleRating (rating) {
    this.setState({ review_rating: rating })
  }

  handleCloseModal () {
    this.setState({ showModal: false })
  }

  handleFormChange (evt) {
    const value = evt.target.value
    this.setState({
      ...this.state,
      [evt.target.name]: value,
    })
  }

  componentDidMount () {
    if (this.state.name) {
      this.fetchListing().then(() => {
        this.fetcher()
      })
        .catch((error) => {
          this.setState({ error: error })
          console.error(error)
        })
    }
  }

  fetchListing = (e) => {
    return fetch(API_URL +
      `/api/app/${this.state.name}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then(responseData => convertApp(responseData))
      .then(data => {
        if (!data) {
          throw new Error(
            `Could not load data for app /api/app/${this.state.name}`)
        }

        this.setState({
          loading: false,
          data: data,
          reviews: data.reviews ?? [],
          industries: data.subcategories ?? [],
        })

        for (let i = 0; i < this.state.app_likes.length; i++) {
          const element = this.state.app_likes[i]
          if (element.listing.url === data.url) {
            this.setState({ wishlistAdded: true, url: element.url })
          }
        }
      })
      .catch((error) => {
        this.setState({ error: error })
        console.error(this.state.error)
      })
  }

  postClaim = (e) => {
    this.setState({ claimShowModal: false })
    fetch(process.env.REACT_APP_API_URL + `/claims/apps/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        listing: this.state.data.url,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ sendingClaim: false, claimSent: true })
      })
      .catch((error) => {
        this.setState({ error: error })
        console.error(this.state.error)
      })
  }

  postReview = (e) => {
    this.setState({ sendingReview: true })
    fetch(API_URL + `/api/reviews/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        data: {
          type: 'APP',
          app: this.state.data.id,
          rating: this.state.review_rating,
          comment: this.state.review_content,
          note: this.state.review_content,
        },
      }),
    })
      .then((response) => response.json())
      .then((responseData) => {
        const data = convertPostReview('apps', responseData)
        let dataarry = []
        if (data.url) {
          this.setState({ sendingReview: false, reviewSent: true })
        } else {
          for (const [key, value] of Object.entries(data)) {
            dataarry.push(`${key}: ${value}`)
          }
          this.setState({
            errorsreview: dataarry,
          })
        }
      })
      .catch((error) => {
        this.setState({ errorreview: error })
        console.error(this.state.error)
      })
  }

  goBack () {
    window.history.back()
  }

  removeFromToolkit = (i) => {
    if (this.state.app_likes.length > 0) {
      const likeId = this.state.app_likes[0].id
      fetch(
        API_URL + `/api/likes/${likeId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
        },
      )
        .then((data) => {
          this.setState({ wishlistAdded: false })
        })
        .catch((error) => {
          this.setState({ error: error })
          console.error(this.state.error)
        })
    }
  }

  saveListing = (e) => {
    fetch(API_URL + `/api/likes/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        data: {
          app: this.state.data.id,
        },
      }),
    })
      .then((response) => response.json())
      .then((responseData) => {
        this.setState({ wishlistAdded: true })
        this.fetcher()
      })
      .catch((error) => {
        this.setState({ error: error })
        console.error(this.state.error)
      })
  }

  render () {
    const {
      reviews,
      data,
      claimSent,
      sendingClaim,
      loading,
      showModal,
      claimShowModal,
      admin,
      wishlistAdded,
      reviewSent,
      sendingReview,
    } = this.state
    var handleOpenModal = this.handleOpenModal
    if (loading) {
      return (
        <div id="profile">
          <div className="gc no-gap profileHeader">
            <div className="d-2-12 t-2-12 m-2-12 ">
              <a onClick={this.goBack}>
                <div className="goback">
                  <span className="white pointer">
                    <FontAwesomeIcon icon={faLongArrowAltLeft} /> Go Back
                  </span>
                </div>
              </a>
            </div>
            <div className="d-2-12 t-2-12 m-1-13 ">
              <div id="profilePictureContainer"
                   className="d-2-12 t-2-12 m-1-13 ">
                <div className="profilepicture">
                  <img
                    src={data.logo ? data.logo : noImage}
                    alt="logo"
                    itemProp="logo"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div className="gc no-gap splashscreen">
            <div className="d-2-12 t-3-11 m-1-13 ">
              <svg className="splash-spinner" viewBox="0 0 50 50">
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div id="profile" itemScope itemType="//schema.org/Organization">
          {' '}
          <Helmet>
            <title>
              {data.name} - The Right Tool - Aotearoa’s leading app marketplace
            </title>
            <meta
              name="description"
              content={`Read real reviews for ${data.name} - ${data.short_description} `}
            />
          </Helmet>
          <a className="skiplink" href="#profile">
            Skip To Content
          </a>
          <ReactModal
            className="modal gc claimModal"
            isOpen={this.state.claimShowModal}
            contentLabel="Minimal Modal Example"
            closeTimeoutMS={500}
          >
            <div className="form_container d-1-13 ">
              <div className="review_modal_inner">
                <div></div>
                <div onClick={this.handleClaimCloseModal}
                     className="pointer fasvg">
                  <button onClick={this.handleClaimCloseModal}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              </div>
            </div>

            {!sendingClaim && !claimSent ? (
              <>
                <div className="form_container d-1-13 ">
                  <h4>Are you sure?</h4>
                  <span className="darkgrey">
                    Is this your app? Send a claim request to gain access.
                  </span>
                </div>
                {' '}
                <div className="form_container d-1-13">
                  <div className="split_between">
                    <div className="pointer"
                         onClick={this.handleClaimCloseModal}>
                      <Button text={'Cancel'} color="grey" />
                    </div>
                    <div className="pointer" onClick={this.postClaim}>
                      <Button text={'Confirm'} color="blue" />
                    </div>
                  </div>
                </div>
                {' '}
              </>
            ) : null}
          </ReactModal>
          <ReactModal
            className="modal gc "
            isOpen={this.state.showModal}
            contentLabel="Minimal Modal Example"
            closeTimeoutMS={500}
          >
            <div className="form_container d-1-13 ">
              <div className="review_modal_inner">
                <div></div>
                <div onClick={this.handleCloseModal} className="pointer fasvg">
                  <button onClick={this.handleCloseModal}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              </div>
            </div>
            {reviewSent ? (
              <h4 className="thankyou">Thank you for the review</h4>
            ) : null}
            {sendingReview ? (
              <svg className="splash-spinner" viewBox="0 0 50 50">
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            ) : null}
            {!sendingReview && !reviewSent ? (
              <>
                <div className="form_container d-1-13 ">
                  <img
                    src={data.logo ? data.logo : noImage}
                    alt="The listing logo"
                  ></img>
                  <h4>{data.name} Review</h4>
                  <span className="darkgrey">
                    Leave a review - please note these go through an approval
                    process.
                  </span>

                  <div>
                    <ReactStars
                      value={this.state.review_rating}
                      onChange={(rating) => this.handleRating(rating)}
                      size={30}
                      activeColor={'#FAAA00'}
                      color={'#767676'}
                    />
                  </div>
                  <div>
                    <span className="subline">Review</span>
                    <textarea
                      value={this.review_content}
                      onChange={this.handleFormChange}
                      name="review_content"
                      // className={errors.username ? "errorinputfield" : ""}
                    />
                  </div>
                  <div className="errorbox">
                    {' '}
                    {this.state.errorsreview.map((x, i) => (
                      <li>{x}</li>
                    ))}{' '}
                  </div>
                </div>
                {' '}
                <div className="form_container d-1-13">
                  <div className="split_between">
                    <div className="pointer" onClick={this.handleCloseModal}>
                      <button onClick={this.handleCloseModal}>
                        <Button text={'Cancel'} color="grey" />
                      </button>
                    </div>
                    <div className="pointer">
                      <button onClick={this.postReview}>
                        <Button
                          classname="sendmodaltag"
                          text={'Confirm'}
                          color="blue"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                {' '}
              </>
            ) : null}
          </ReactModal>
          <div className="gc no-gap profileHeader">
            <div className="d-2-12 t-2-12 m-2-12 ">
              <a onClick={this.goBack}>
                <div className="goback">
                  <span className="white pointer">
                    <FontAwesomeIcon icon={faLongArrowAltLeft} /> Go Back
                  </span>
                </div>
              </a>
            </div>
            <div id="profilePictureContainer" className="d-2-12 t-2-12 m-1-13 ">
              <div className="profilepicture">
                <img
                  src={data.logo ? data.logo : noImage}
                  alt="The listing logo"
                  itemProp="logo"
                ></img>
              </div>
            </div>
          </div>
          {data.special_offers.length > 1 && (
            <Offer data={data.special_offers} />
          )}
          <div
            className={`gc no-gap ${
              data.special_offers.length > 0 ? 'specialoffercontent' : 'content'
            } opacityfade`}
          >
            <div className="d-4-12 t-2-12 m-2-12 profile_mobile">
              <span className="industry">
                {' '}
                {this.state.industries.map((industry, i) => {
                  let length = this.state.industries.length
                  if (length === i + 1) {
                    return `${industry.name}`
                  } else {
                    return `${industry.name}, `
                  }
                })}
              </span>
              <div className="profile_position">
                <div className="heartPosition">
                  <h1 itemProp="name" className="inline-block ">
                    {data.name}
                  </h1>
                  <div className={`${token ? null : 'hide'}`}>
                    <FontAwesomeIcon
                      className={
                        token && wishlistAdded
                          ? 'pointer hearticon inline-block  heartFilledYes'
                          : 'pointer heartOutline'
                      }
                      icon={wishlistAdded ? faHeart : farHeart}
                      onClick={
                        wishlistAdded
                          ? this.removeFromToolkit
                          : this.saveListing
                      }
                    />
                  </div>
                </div>
                <div className="d-hide t-hide">
                  <ReactStars
                    value={data.rating ? data.rating : 0}
                    onChange={(rating) => this.handleRating(rating)}
                    size={30}
                    edit={false}
                    activeColor={'#FAAA00'}
                    color={'#767676'}
                  />
                  <span className="darkgrey"> {reviews.length} Reviews</span>
                </div>
                <div id="profileHeaderBtns" className="btn_container">
                  {data.free_trial ? (
                    <Button
                      classname={'freetrial'}
                      text={'Start free trial'}
                      color="green"
                      link={data.free_trial}
                    />
                  ) : (
                    ''
                  )}
                  {admin && token && !claimSent ? (
                    <span
                      onClick={token ? () => this.handleClaimOpenModal() : ''}
                      className="pointer subline"
                    >
                      Claim this listing
                    </span>
                  ) : (
                    ''
                  )}
                  {admin && !claimSent && !token ? (
                    <span className="pointer subline">
                      <a
                        href={`https://admin.therighttool.co.nz/auth/login?returnTo=${window.location.pathname}`}
                      >
                        Sign up to claim this listing
                      </a>
                    </span>
                  ) : (
                    ''
                  )}
                  {admin && token && claimSent ? (
                    <span className="pointer subline">Claim request sent</span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="m-hide starsnew">
                <ReactStars
                  value={data.rating ? data.rating : 0}
                  onChange={(rating) => this.handleRating(rating)}
                  size={30}
                  edit={false}
                  activeColor={'#FAAA00'}
                  color={'#767676'}
                />
                <span className="darkgrey"> {reviews.length} Reviews</span>
              </div>
            </div>
            <div className="d-4-12 t-2-12 m-2-12 inner">
              <h4>About</h4>

              <p itemProp="description" className="main_p">
                {data.long_description}
              </p>
              <p className="main_p pricing_p">{data.pricing}</p>
              {data.link &&
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={data.link}
                  itemProp="website"
                  className="blue main_p freetrial"
                >
                  Visit Website
                </a>
              }
            </div>
            <ReviewContainer
              handleOpenModal={handleOpenModal.bind(this)}
              reviews={reviews}
            />
          </div>
          <ServiceCta
            text={`Meet cloud experts who can help you with ${data.name}`}
            sub={
              'Find cloud experts that can get you started on any of these apps. So you can get the help when you need it.'
            }
            button_text={'View More Cloud Experts'}
            button_link={''}
          />
          <CTA
            text={'Want your app in the directory?'}
            sub={'Get your app to show for kiwi businesses today.'}
            button_text={'List My App'}
            button_link={'/list-an-app'}
          />
        </div>
      )
    }
  }
}

export default profileapps
