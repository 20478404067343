import { useState } from 'react'
import './listing.scss'
import OfferToolTip from './offertooltip'
import noImage from '../../assets/images/noImage.png'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NZToolTip from '../listing/newownedtooltip'
import WishlistButton from '../wishlist/WishlistButton'

const stripURL = (url) => {
  const temp = url.replace(/\/$/, '')
  return temp.replace(/^(.*[\\\/])/g, '')
}

const NewListing = (props) => {
  const [app] = useState(props.app)
  const [service] = useState(props.service)
  const [link] = useState(
    `${props.link}/${stripURL(props.app ? props.app.url : props.service.url)}`)

  const [colour] = useState(props.blue)

  const contentType = () => {
    if (app) return 'app'
    return 'service'
  }

  const getId = () => {
    if (app) return app.id
    return service.id
  }

  const redirect = (e) => {
    if (e.target.classList.contains('wishlist-button') ||
      e.target.parentNode.classList.contains('wishlist-button') ||
      e.target.parentNode.parentNode.classList.contains('wishlist-button')) {
      return
    }

    window.location.href = `${link}`
  }

  const data = app ? app : service

  return (
    <div
      className={`listing_new ${props.className ? props.className : ''} ${
        colour ? 'blue_listing' : ''
      }`}
      onClick={(e) => redirect(e)}
    >
      <div className="listingLogo_new">
        <img
          src={
            data.logo === null || data.logo === undefined || data.logo === ''
              ? noImage
              : data.logo
          }
          alt="The Listing logo"
        ></img>
      </div>
      <div className="listingDetail_new">
        <div className="listingWrapper_new">
          <div>
            <WishlistButton listingId={getId()}
                            listingType={contentType()} />
            {data.special_offers.length > 0 && (
              <OfferToolTip
                id={`${data.name}_offer`}
                colour={props.blue}
                data={data.special_offers}
              />
            )}
            {data.nz_owned && (
              <NZToolTip
                id={`${data.name}_nz`}
                right={data.special_offers.length > 0}
              />
            )}

            <h4>{data.name}</h4>
          </div>
        </div>
        <p className="listing_p_new">
          {data.short_description.length > 110
            ? data.short_description.substr(0, 110 - 1) + '...'
            : data.short_description}
        </p>

        <a href={link}>
          <span className="blue">
            Learn more &nbsp;
            <FontAwesomeIcon icon={faLongArrowAltRight} />
          </span>
        </a>
      </div>
    </div>
  )
}

export default NewListing
