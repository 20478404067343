import React, { Component } from 'react'
import './testimonial.scss'
import noImage from '../../assets/images/noImage.png'
import baytours from '../../assets/images/baytours.jpg'
import baytourslogo from '../../assets/images/baytours.png'
import hiddenlake from '../../assets/images/hiddenlake.png'
import hiddenlakeimage from '../../assets/images/hiddenlakeimage.jpg'
import loxylogo from '../../assets/images/loxylogo.png'
import loxy from '../../assets/images/loxy.jpg'
import unicornfactory from '../../assets/images/unicornfactoryimage.png'
import unicornfactorylogo from '../../assets/images/unicornfactory.png'
import wildpilates from '../../assets/images/wildpilates.png'
import wildpilatesimg from '../../assets/images/wildpilatesimg.jpg'
import oddthinglogo from '../../assets/images/oddthinglogo.png'
import oddthingimage from '../../assets/images/oddthingimage.jpg'
import thebeerspotlogo from '../../assets/images/thebeerspotlogo.png'
import thebeerspotimage from '../../assets/images/thebeerspotimage.jpg'
import goodfor from '../../assets/images/goodforlogo.webp'
import goodforlogo from '../../assets/images/goodforlogo.png'
import Button from '../button/button'
import { convertApp } from '../../util/api_converters'
import { API_URL } from '../../constraints'
import ReactMarkdown from 'react-markdown'
// import { faFacebookSquare, faInstagram } from "@fortawesome/free-brands-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Testimonial extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selected: [],
      NewNeedData: [],
      NewGrowData: [],
      NewManageData: [],
      casestudies: [
        {
          industry: 'tourism',
          Iname: 'Tourism',
          name: 'Bay Tours',
          qoutename: 'Kerren Withington - Bay Tours',
          qoute:
            'We choose apps that automate & increase productivity so we can focus on what we do best - providing the best senior travel in New Zealand.',
          link: 'https://www.baytours.co.nz',
          logo: baytourslogo,
          image: baytours,
          need: [
            {
              app: 'Xero',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=xero&filters[$or][1][subcategories][name][$containsi]=xero&populate=*',
            },
          ],
          manage: [
            {
              app: 'Knack',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=knack&filters[$or][1][subcategories][name][$containsi]=knack&populate=*',
            },
            {
              app: 'Zapier',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=zapier&filters[$or][1][subcategories][name][$containsi]=zapier&populate=*',
            },
            {
              app: 'Slack',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=slack&filters[$or][1][subcategories][name][$containsi]=slack&populate=*',
            },
            {
              app: 'Typeform',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=typeform&filters[$or][1][subcategories][name][$containsi]=typeform&populate=*',
            },
          ],
          grow: [
            {
              app: 'Mailchimp',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=mailchimp&filters[$or][1][subcategories][name][$containsi]=mailchimp&populate=*',
            },
            {
              app: 'Hootsuite',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=hootsuite&filters[$or][1][subcategories][name][$containsi]=hootsuite&populate=*',
            },
          ],
        },
        {
          industry: 'accomodation',
          Iname: 'Accomodation Providers',
          name: 'Hidden Lake Hotel',
          qoutename: 'Glenda Hamilton - Hidden Lake Hotel',
          qoute:
            'Using the right apps in our business helps us keep a lid on costs and our business viable so that we can keep delivering a superior and sustainable experience for our customers.',
          link: 'https://www.hiddenlakehotel.co.nz',
          logo: hiddenlake,
          image: hiddenlakeimage,
          need: [
            {
              app: 'Xero',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=xero&filters[$or][1][subcategories][name][$containsi]=xero&populate=*',
            },
            {
              app: 'Allotz',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=allotz&filters[$or][1][subcategories][name][$containsi]=allotz&populate=*',
            },
            {
              app: 'roomMaster',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=roommaster&filters[$or][1][subcategories][name][$containsi]=roommaster&populate=*',
            },
          ],
          manage: [
            {
              app: 'Deputy',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=deputy&filters[$or][1][subcategories][name][$containsi]=deputy&populate=*',
            },
            {
              app: 'Zapier',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=zapier&filters[$or][1][subcategories][name][$containsi]=zapier&populate=*',
            },
            {
              app: 'Typeform',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=typeform&filters[$or][1][subcategories][name][$containsi]=typeform&populate=*',
            },
          ],
          grow: [
            {
              app: 'Mailchimp',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=mailchimp&filters[$or][1][subcategories][name][$containsi]=mailchimp&populate=*',
            },
            {
              app: 'Hootsuite',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=hootsuite&filters[$or][1][subcategories][name][$containsi]=hootsuite&populate=*',
            },
            {
              app: 'Expedia Travel Ads',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=expediat-travel-ads&filters[$or][1][subcategories][name][$containsi]=expediat-travel-ads&populate=*',
            },
          ],
        },
        {
          industry: 'advisory',
          Iname: 'Accounting',
          name: 'Good for Business',
          qoutename: 'Chun M Chung - Good for Business',
          qoute:
            'We fully utilise technology and apps both internally and with clients to save time, money and improve efficiencies. With more than all these tools that integrate with each other you have the equivalent to a digital Swiss Army Knife on steroids.',
          link: 'https://www.goodforbusiness.co.nz',
          logo: goodforlogo,
          image: goodfor,
          need: [
            {
              app: 'Xero',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=xero&filters[$or][1][subcategories][name][$containsi]=xero&populate=*',
            },
            {
              app: 'Hubdoc',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=hubdoc&filters[$or][1][subcategories][name][$containsi]=hubdoc&populate=*',
            },
            {
              app: 'Go Cardless',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=go-cardless&filters[$or][1][subcategories][name][$containsi]=go-cardless&populate=*',
            },
            {
              app: 'Stripe',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=stripe&filters[$or][1][subcategories][name][$containsi]=stripe&populate=*',
            },
          ],
          manage: [
            {
              app: 'Campaign Monitor',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=campagin-monitor&filters[$or][1][subcategories][name][$containsi]=campagin-monitor&populate=*',
            },
            {
              app: 'Pandadoc',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=pandadoc&filters[$or][1][subcategories][name][$containsi]=pandadoc&populate=*',
            },
            {
              app: 'Integromat',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=integromat&filters[$or][1][subcategories][name][$containsi]=integromat&populate=*',
            },
          ],
          grow: [
            {
              app: 'Mailchimp',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=mailchimp&filters[$or][1][subcategories][name][$containsi]=mailchimp&populate=*',
            },
          ],
        },
        {
          industry: 'beauty-',
          Iname: 'Beauty Services',
          name: 'Loxy\'s',
          qoutename: 'Kate Jerret - Loxy’s',
          qoute:
            'They help me manage every touch point of my business & keep it operating to it\'s full potential & from any location',
          link: 'https://www.loxys.co.nz',
          logo: loxylogo,
          image: loxy,
          need: [
            {
              app: 'Xero',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=xero&filters[$or][1][subcategories][name][$containsi]=xero&populate=*',
            },
            {
              app: 'Timely',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=timely&filters[$or][1][subcategories][name][$containsi]=timely&populate=*',
            },
          ],
          manage: [
            {
              app: 'Slack',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=slack&filters[$or][1][subcategories][name][$containsi]=slack&populate=*',
            },
            {
              app: 'Outlook',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=outlook&filters[$or][1][subcategories][name][$containsi]=outlook&populate=*',
            },
          ],
          grow: [
            {
              app: 'Mailchimp',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=mailchimp&filters[$or][1][subcategories][name][$containsi]=mailchimp&populate=*',
            },
            {
              app: 'Buffer',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=buffer&filters[$or][1][subcategories][name][$containsi]=buffer&populate=*',
            },
          ],
        },
        {
          industry: 'fitness',
          Iname: 'Health/Fitness',
          name: 'Wild Pilates',
          qoutename: 'Laura Mohi - Wild Pilates',
          qoute:
            'With the right tools I can focus more energy on creating Pilates sessions not doing admin.',
          link: 'https://www.wildpilates.co.nz',
          logo: wildpilates,
          image: wildpilatesimg,
          need: [
            {
              app: 'Xero',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=xero&filters[$or][1][subcategories][name][$containsi]=xero&populate=*',
            },
          ],
          manage: [
            {
              app: 'Dropbox',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=dropbox&filters[$or][1][subcategories][name][$containsi]=dropbox&populate=*',
            },
            {
              app: 'Lightroom',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=lightroom&filters[$or][1][subcategories][name][$containsi]=lightroom&populate=*',
            },
            {
              app: 'Unfold',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=unfold&filters[$or][1][subcategories][name][$containsi]=unfold&populate=*',
            },
          ],
          grow: [
            {
              app: 'Mailchimp',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=mailchimp&filters[$or][1][subcategories][name][$containsi]=mailchimp&populate=*',
            },
            {
              app: 'Buffer',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=buffer&filters[$or][1][subcategories][name][$containsi]=buffer&populate=*',
            },
          ],
        },
        {
          industry: 'freelancers',
          name: 'Connor Finlayson',
          Iname: 'Contractors/Gig Economy/Freelancers',
          qoutename: 'Connor Finlayson - Unicorn Factory',
          qoute:
            'It allows me to run my business more time and cost effectively.',
          link: 'https://www.ConnorFinlayson.co.nz',
          logo: unicornfactorylogo,
          image: unicornfactory,
          need: [
            {
              app: 'Xero',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=xero&filters[$or][1][subcategories][name][$containsi]=xero&populate=*',
            },
            {
              app: 'Webflow',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=webflow&filters[$or][1][subcategories][name][$containsi]=webflow&populate=*',
            },
          ],
          manage: [
            {
              app: 'Zapier',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=zapier&filters[$or][1][subcategories][name][$containsi]=zapier&populate=*',
            },
            {
              app: 'Airtable',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=airtable&filters[$or][1][subcategories][name][$containsi]=airtable&populate=*',
            },
            {
              app: 'Notion',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=notion&filters[$or][1][subcategories][name][$containsi]=notion&populate=*',
            },
          ],
          grow: [
            {
              app: 'Mailchimp',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=mailchimp&filters[$or][1][subcategories][name][$containsi]=mailchimp&populate=*',
            },
          ],
        },
        {
          industry: 'retail',
          name: 'ODDTHING',
          Iname: 'Retail',
          qoutename: 'Henry Caird - ODDTHING',
          qoute:
            'We are continually discovering new tools to streamline the business, curate our brand, and improve our customer experience. ODDTHING would not be a thing without these services.',
          link: 'https://oddthing.co.nz/',
          logo: oddthinglogo,
          image: oddthingimage,
          need: [
            {
              app: 'Lightroom',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=lightroom&filters[$or][1][subcategories][name][$containsi]=lightroom&populate=*',
            },
            {
              app: 'Photoshop',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=photoshop&filters[$or][1][subcategories][name][$containsi]=photoshop&populate=*',
            },
          ],
          manage: [
            {
              app: 'Squarespace',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=squarespace&filters[$or][1][subcategories][name][$containsi]=squarespace&populate=*',
            },
          ],
          grow: [
            {
              app: 'Paypal',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=paypall&filters[$or][1][subcategories][name][$containsi]=paypall&populate=*',
            },
          ],
        },
        {
          industry: 'hospitality',
          Iname: 'Hospitality',
          name: 'Adrian Wills',
          qoutename: 'Adrian Wills - The Beer Spot',
          qoute:
            'Running a business is hard enough without process getting in the way. The right digital solutions make life easier.',
          link: 'https://thebeerspot.co.nz/',
          logo: thebeerspotlogo,
          image: thebeerspotimage,
          need: [
            {
              app: 'MYOB',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=myob&filters[$or][1][subcategories][name][$containsi]=myob&populate=*',
            },
            {
              app: 'Flexitime',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=flexitime&filters[$or][1][subcategories][name][$containsi]=flexitime&populate=*',
            },
          ],
          manage: [
            {
              app: 'Trickle',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=trickle&filters[$or][1][subcategories][name][$containsi]=trickle&populate=*',
            },
            {
              app: 'Posboss',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=posboss&filters[$or][1][subcategories][name][$containsi]=posboss&populate=*',
            },
          ],
          grow: [
            {
              app: 'Mailchimp',
              url: API_URL +
                '/api/apps?filters[$or][0][name][$containsi]=mailchimp&filters[$or][1][subcategories][name][$containsi]=mailchimp&populate=*',
            },
          ],
        },
      ],
    }
  }

  componentDidMount () {
    this.state.casestudies.forEach(element => {
      if (element.industry === this.props.industry) {
        this.setState({ selected: element })
        for (let i = 0; i < element.grow.length; i++) {
          fetch(`${element.grow[i].url}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then(response => response.json())
            .then(responseData => {
              const data = convertApp(responseData.data[0])
              if (!data.detail) {
                let newIds = this.state.NewGrowData.slice() //copy the array
                newIds[i] = data
                this.setState({ NewGrowData: newIds })
              }
            })
            .catch(error => {
              this.setState({ error: error })
              console.error(this.state.error)
            })
        }
        for (let i = 0; i < element.manage.length; i++) {
          fetch(`${element.manage[i].url}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then(response => response.json())
            .then(responseData => {
              const data = convertApp(responseData.data[0])
              if (!data.detail) {
                const newIds = this.state.NewManageData.slice() //copy the array
                newIds[i] = data
                this.setState({ NewManageData: newIds })
              }
            })
            .catch(error => {
              this.setState({ error: error })
              console.error(this.state.error)
            })
        }

        for (let i = 0; i < element.need.length; i++) {
          fetch(`${element.need[i].url}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then(response => response.json())
            .then(responseData => {
              const data = convertApp(responseData.data[0])
              if (!data.detail) {
                const newIds = this.state.NewNeedData.slice() //copy the array
                newIds[i] = data
                this.setState({ NewNeedData: newIds })
              }
            })
            .catch(error => {
              this.setState({ error: error })
              console.error(this.state.error)
            })
        }
      }
    })
  }

  render () {
    const main_text = this.props.text
    const display_cta = this.props.cta
    const { selected, NewNeedData, NewManageData, NewGrowData } = this.state
    return (
      <div
        id="testimonial"
        className={`gc no-gap ${selected.length != 0 ? '' : 'hide'}`}
      >
        <div className="d-4-10 t-3-11 m-2-12 testimonial_header text_center">
          <h2>{main_text}</h2>
        </div>
        <div
          className="d-2-8 t-2-12 m-1-13 background_grey testimonail_container"
          id="main"
        >
          <img
            src={selected.logo}
            alt="The testimonial providers logo"
            id="gglogo"
          />
          <span className="italics">“{selected.qoute}"</span>
          <div className="bagel_owner">
            <div className="inline-block center_vert">
              <span className="strong_span owner">{selected.qoutename}</span>
            </div>
          </div>
        </div>
        <div
          className="d-8-12 t-2-12 m-1-13 background_grey testimonail_container"
          id="plate"
        >
          <img
            id="platehospo"
            src={selected.image}
            alt="The testimonial providers logo"
          />
        </div>
        <div className="d-2-12 t-2-12 m-1-13 app_container_testi">
          <div className=" item_toolbox background_grey">
            <h3>Find tools you need</h3>
            {NewNeedData.map(app => {
              if (app.short_description === undefined) {
                return null
              } else {
                let shortenedbrief = app.short_description
                if (shortenedbrief.length > 50) {
                  shortenedbrief =
                    app.short_description.substr(0, 70 - 1) + '&hellip;'
                  return (
                    <div className="toolbox_app_container">
                      <div>
                        <img
                          src={app.logo ? app.logo : noImage}
                          alt="the testimonial providers logo"
                        ></img>
                      </div>
                      <div>
                        <h6 className="blue">{app.name}</h6>
                        <span>
                          <ReactMarkdown
                            escapeHtml={false}
                            source={shortenedbrief}
                          />
                        </span>
                      </div>
                    </div>
                  )
                }
              }
            })}
          </div>
          <div className=" item_toolbox background_grey">
            <h3>Tools to manage</h3>
            {NewManageData.map(app => {
              if (app.short_description === undefined) {
                return null
              } else {
                let shortenedbrief = app.short_description
                if (shortenedbrief.length > 50) {
                  shortenedbrief =
                    app.short_description.substr(0, 70 - 1) + '&hellip;'
                }
                return (
                  <div className="toolbox_app_container">
                    <div>
                      <img
                        src={app.logo ? app.logo : noImage}
                        alt="the testimonial providers logo"
                      ></img>
                    </div>
                    <div>
                      <h6 className="blue">{app.name}</h6>
                      <span>
                        <ReactMarkdown
                          escapeHtml={false}
                          source={shortenedbrief}
                        />
                      </span>
                    </div>
                  </div>
                )
              }
            })}
          </div>

          <div className=" item_toolbox background_grey">
            <h3>Tools to grow</h3>
            {NewGrowData.map(app => {
              if (app.short_description === undefined) {
                return null
              } else {
                let shortenedbrief = app.short_description
                if (shortenedbrief.length > 50) {
                  shortenedbrief =
                    app.short_description.substr(0, 70 - 1) + '&hellip;'
                  return (
                    <div className="toolbox_app_container">
                      <div>
                        <img
                          src={app.logo ? app.logo : noImage}
                          alt="the testimonial providers logo"
                        ></img>
                      </div>
                      <div>
                        <h6 className="blue">{app.name}</h6>
                        <span>
                          <ReactMarkdown
                            escapeHtml={false}
                            source={shortenedbrief}
                          />
                        </span>
                      </div>
                    </div>
                  )
                }
              }
            })}
          </div>
        </div>

        <div
          id="discover"
          className="d-2-12 t-2-12 m-1-13 background_blue text_center buffer"
        >
          <Button
            text="Discover experts who can help you get set up with these tools"
            color="blue"
            link={`/directory/cloud-experts/${this.props.industry}?n=${selected.Iname}`}
          />
        </div>
      </div>
    )
  }
}

export default Testimonial
