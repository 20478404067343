import React, { Component } from 'react'
import './filtermain_old.scss'
import digitalBoostSmall from '../../assets/images/digitalBoostSmall.png'

class FilterMainOld extends Component {
  constructor (props) {
    super(props)
    this.state = {
      directory: '',
      message: '',
    }
  }

  callbackFunction = (childData, url) => {
    this.setState({ message: childData })
    this.props.parentParentCallback(childData, url)

    this.setState({ directory: childData })
  }

  componentDidMount () {
    if (window.location.href.indexOf('http://localhost:3000') > -1) {
      let direct = window.location.href.split('/').slice(-1).join('/')
      direct = direct.substr(0, (direct + '?').indexOf('?'))
      this.setState({
        message: direct.charAt(0).toUpperCase() + direct.substring(1),
      })
    } else {
      this.setState({ message: '' })
    }
  }

  render () {
    const main_text = this.props.text
    const display = this.props.search

    let name_directory

    if (!this.state.message && this.props.homepagepass) {
      name_directory =
        this.props.homepagepass.charAt(0).toUpperCase() +
        this.props.homepagepass.substring(1)
    } else {
      name_directory =
        this.state.message.charAt(0).toUpperCase() +
        this.state.message.substring(1)
    }

    return (
      <div className="gc no-gap filtermain_old center_all">
        <div className="d-3-11 t-3-11 m-2-12 text_center">
          <span className={this.state.directory ? 'subheading' : 'none'}>
            {this.props.top}
          </span>
          <h1>
            {' '}
            {main_text}&nbsp;{+display ? unescape(name_directory) : ''}
          </h1>

          <span className={this.props.bottom ? 'subheading' : 'none'}>
            {this.props.bottom}
          </span>
        </div>

        <div className="gov_logo">
          <a title="open external link"
             href="https://digitalboostalliance.nz//">
            <img
              src={digitalBoostSmall}
              alt="Small Business Digital Boost Logo"
            ></img>
          </a>
        </div>
      </div>
    )
  }
}

export default FilterMainOld
