import Results from "../layouts/results";
import { Redirect, Route, Switch } from "react-router-dom";
import { JSX } from 'react'
import services from "../pages/services";
import apps from "../pages/apps";

export default function DirectoryRoute(): JSX.Element {
    return (
        <Route path="/directory">
            <Results>
                <Switch>
                    <Route
                        path="/directory/cloud-experts/:handle"
                        exact
                        component={services}
                    />

                    <Redirect
                        from="/directory/cloud-experts/:handle/r/"
                        to="/directory/cloud-experts/:handle"
                    />
                    <Route path="/directory/apps/:handle" exact component={apps} />
                    <Redirect
                        from="/directory/apps/:handle/r/"
                        to="/directory/apps/:handle"
                    />
                    <Route render={() => <Redirect to="/" />} />
                </Switch>
            </Results>
        </Route>
    )
}