import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import digitalBoostSmall from '../../assets/images/digitalBoostSmall.png'
import { industries } from '../../util/service'
import Filter from '../../components/filter/filter'
import { getCategoryOrIndustry } from '../../helpers/tools'
// This file appears to be used as the filter in the banner above Apps/Cloud Experts for the tools page
const NewFilterMain = (props) => {
  const [category, setCategory] = useState(null)
  const [industry, setIndustry] = useState(null)
  let history = useHistory()

  useEffect(() => {
    const { category: cat, industry: ind } = getCategoryOrIndustry()
    if (cat) {
      setCategory(cat)
    }

    if (cat && ind) {
      setIndustry(ind)
    }
  }, [])

  const setFilter = (arg) => {
    if (arg === 'All') {
      history.push(`/tools/${category}`)
      return
    }
    history.push(`/tools/${category}/${arg}`)
  }

  if (!category) {
    return (<div></div>)
  }

  return (
    <div
      className={`gc no-gap filtermain newbackground center_all flex ${category}`}>
      <div className="d-3-11 t-3-11 m-2-12 text_center">
        <h1>
          {`The Right `}
          <span className="background_red">{category.replace(/-/g,
            ' ')}</span>{' '}
          Tools
        </h1>
      </div>

      <div className="gov_logo">
        <a title="open external link" href="https://digitalboostalliance.nz/">
          <img
            src={digitalBoostSmall}
            alt="Small Business Digital Boost Logo"
          ></img>
        </a>
      </div>
      <div
        className="d-3-11 t-3-11 m-2-12 text_center filtermain_filter_container">
        <Filter
          id="1filterul"
          idlist="filterlist"
          query={industries}
          currentIndustry={industry}
          setFilter={setFilter.bind(this)}
        />
      </div>
    </div>
  )
}

export default NewFilterMain
