import { JSX } from 'react'

import Router from './router'
import './assets/css/grid.scss'
import './App.scss'

function App(): JSX.Element {
    return (
        <Router />
    )
}

export default App
