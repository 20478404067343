import fadeIn from './fadeIn'

const fadeInElements = (className = 'animate') => {
  let itemsToFade = document.getElementsByClassName(className)
  if (itemsToFade && itemsToFade.length > 0) {
    let i = 0
    for (let item of itemsToFade) {
      setTimeout(() => fadeIn(item, 500), (200 * i))
      i++
    }
  }
}

export default fadeInElements