import { Fragment, useState } from 'react'
import './featuredindustry.scss'
import NewListing from '../../components/newlisting/newlisting'
import next_swiper from '../../assets/images/next_swiper.svg'
import prev_swiper from '../../assets/images/prev_swiper.svg'
//swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper/core'
import 'swiper/swiper.min.css'

// install Swiper modules
SwiperCore.use([Autoplay])

const Featuredindustry = (props) => {
  const [services] = useState(
    props.data.featured_services ? props.data.featured_services : [],
  )
  const [apps] = useState(
    props.data.featured_apps ? props.data.featured_apps : [],
  )

  return (
    <>
      <div className="d-2-12 t-2-12 m-2-12 hr_parent">
        {' '}
        <hr />
      </div>

      <div className="d-2-12 t-2-12 m-2-12 text_center">
        <h2>Featured in your industry</h2>
      </div>

      <div className="d-1-2 t-1-2 m-1-2 action_swiper_button">
        <div className="review-swiper-button-prev ">
          <img src={prev_swiper} alt="previous arrow" />
        </div>
      </div>

      <div className="d-2-12 t-2-12 m-2-12 profile">
        <Swiper
          className="mySwiper"
          spaceBetween={80}
          slidesPerView={1}
          breakpoints={{
            950: {
              slidesPerView: 3,
              spaceBetween: 35,
            },
          }}
          centeredSlides={true}
          initialSlide={1}
          loop={true}
          navigation={{
            nextEl: '.review-swiper-button-next',
            prevEl: '.review-swiper-button-prev',
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
        >
          {apps.map((app, i) => {
            return (
              <Fragment key={i}>
                <SwiperSlide>
                  <NewListing
                    app={app}
                    link={`/profile/app`}
                  />
                </SwiperSlide>
              </Fragment>
            )
          })}
        </Swiper>
      </div>
      <div className="d-12-13 t-12-13 m-12-13 action_swiper_button">
        <div className="review-swiper-button-next ">
          {' '}
          <img src={next_swiper} alt="previous arrow" />
        </div>
      </div>

      {services.length > 0 &&
        <>
          <div className="d-1-2 t-1-2 m-1-2 action_swiper_button">
            <div className="service-swiper-button-prev ">
              <img src={prev_swiper} alt="previous arrow" />
            </div>
          </div>
          <div className="d-2-12 t-2-12 m-2-12 profile">
            <Swiper
              spaceBetween={35}
              slidesPerView={1}
              grabCursor={false}
              navigation={{
                nextEl: '.service-swiper-button-next',
                prevEl: '.service-swiper-button-prev',
              }}
            >
              {services.map((service, i) => {
                return (
                  <Fragment key={i}>
                    <SwiperSlide>
                      <NewListing
                        service={service}
                        link={`/profile/cloud-expert`}
                      />
                    </SwiperSlide>
                  </Fragment>
                )
              })}
            </Swiper>
          </div>
          <div className="d-12-13 t-12-13 m-12-13 action_swiper_button">
            <div className="service-swiper-button-next ">
              {' '}
              <img src={next_swiper} alt="previous arrow" />
            </div>
          </div>
        </>
      }
    </>
  )
}

export default Featuredindustry
