import React, { Component } from 'react'
import FilterMainOld from '../components/filter_main/filtermain_old'
import Button from '../components/button/button'
import checkmark from '../assets/images/checkmark.png'
import list from '../assets/images/list.png'
import { Helmet } from 'react-helmet'
import { ADMIN_URL } from '../constraints'
import Cookies from '../services/Cookies'

function ServiceBoxes (props) {
  return (
    <div id="listaservice" className="gc background_light center_all content">
      <div className="d-2-7 t-3-11 m-3-11 text_center">
        <img src={checkmark} alt="checkmark svg" />
        <h2 className="padding_h2">Connect business owners</h2>
        <p className="main_p">
          The Right Tool™ is Aotearoa’s app directory - helping small business
          owners connect with the best apps to help them manage their
          business.{' '}
        </p>
      </div>
      <div className="d-7-12 t-3-11 m-3-11 text_center">
        <img src={list} alt="list svg" />
        <h2 className="padding_h2">Get listed</h2>
        <p className="main_p">
          List your business on The Right Tool™ as a cloud expert and enter our
          cloud expert directory. Once you are verified by our team we can set
          up your profile.
        </p>
      </div>
    </div>
  )
}

function About (props) {
  const token = Cookies.get('AUTH_TOKEN')

  const ctaLink = token
    ? `${ADMIN_URL}/paywall/services`
    : `${ADMIN_URL}/auth/register`

  return (
    <div className="gc no-gap center_all content bubbles">
      <div className="d-4-10 t-3-11 m-2-12 text_center">
        <h2 className="padding_h2">Why list on The Right Tool™</h2>
        <p className="main_p">
          Your listing will give you the opportunity to acquire high buying
          intent web traffic and sales leads from small business owners needing
          your help to set them up on the apps and solutions you offer.
          <br></br>
          <br></br>
          Build your public profile and use The Right Tool™ as a place to
          collect reviews from your clients to build and nurture your community.
          <br></br>
          <br></br>
          Get matched with the right leads for your business, with tailored
          categories specific to business lifestage and industry.
        </p>
      </div>
      <div className="d-4-10 t-3-11 m-1-13 text_center buffer">
        <Button
          text="Get started with your listing"
          color="blue"
          link={ctaLink}
        />
      </div>
    </div>
  )
}

class listservice extends Component {
  render () {
    return (
      <div id="results_page">
        <Helmet>
          <meta
            name="description"
            content="Your listing will give you the opportunity to acquire high buying intent web traffic and sales leads from small business owners needing your help to set them up on the apps and solutions you offer."
          />
          <title>List your cloud expert profile on The Right Tool</title>
        </Helmet>
        <FilterMainOld
          search={false}
          text={'List your business on the Cloud Experts directory'}
          bottom="Connect with SMEs looking for cloud experts like you."
        />
        <ServiceBoxes />
        <About />
      </div>
    )
  }
}

export default listservice
