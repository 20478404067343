import React, { useState } from 'react'
import Reviews from './reviews'
import Button from '../button/button'
import { ADMIN_URL } from '../../constraints'
import Cookie from 'js-cookie'

const token = Cookie.get('AUTH_TOKEN') ? Cookie.get('AUTH_TOKEN') : null

export function ReviewContainer (props) {
  const reviews = props.reviews
  const [visibleBlogs, setVisibleBlogs] = useState(2)
  const handleOpenModal = props.handleOpenModal

  const handleClick = () => {
    setVisibleBlogs((prevVisibleBlogs) => prevVisibleBlogs + 2)
  }

  const reviewMap = reviews.slice(0, visibleBlogs).map((review, i) => {
    return <Reviews data={review} key={`key_${i}`} />
  })
  return (
    <>
      <div className="d-4-12 t-2-12 m-2-12">
        <div className="split_between reviewContainer">
          <h4>Reviews</h4>
          <p className={reviews.length < 1 ? 'main_p' : 'hide'}>No reviews
            yet</p>
          <div className="pointer"
               onClick={token ? () => handleOpenModal() : ''}>
            {' '}
            <Button
              text={token ? 'Write a Review' : 'Sign up to leave a Review'}
              color="white"
              link={
                token
                  ? null
                  : ADMIN_URL
              }
            />{' '}
          </div>
        </div>
      </div>
      <div className="d-4-12 t-2-12 m-2-12 gc">{reviewMap}</div>
      <div
        onClick={handleClick}
        className="d-4-12 t-2-12 m-2-12 text-center flex center_all padding20"
      >
        <div className={`${reviews.length <= visibleBlogs ? 'hide' : ''} `}>
          {' '}
          {reviews.length > 2 ? (
            <Button
              text={'Show more'}
              color="white"
              className={`${reviews.length <= visibleBlogs ? 'hide' : ''} `}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  )
}