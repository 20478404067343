import "./HomeCard.scss"

export type HomeCardInfoProps = {
    title: string
    subtitle: string
    copy?: string

    href?: string
    label?: string
}

export default function HomeCardInfo(props: HomeCardInfoProps) {
    return (
        <div className={"home-card--regular--grey"}>
            <div className={"home-card__content"}>
                <div>
                    <div>
                        <h4 className={"home-card__title"}>{props.title}</h4>
                        <p className={"home-card__subtitle"}>{props.subtitle}</p>

                        <p className={"home-card__body"}>
                            {props.copy}
                        </p>
                        {props.href && (
                            <div className={"home-card__button"}>
                                <a href={props.href}>
                                <span>
                                    {props.label || "Learn more"}
                                </span>
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}