import React, { Component } from 'react'
import './small_cta.scss'
import Button from '../button/button'
import gov_white from '../../assets/images/gov_white.png'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class SmallCTA extends Component {

  render () {
    const main_text = this.props.text
    const sub = this.props.sub
    const button_text = this.props.button_text
    const button_link = this.props.button_link

    return (
      <div
        className="gc no-gap gradient_blue_cta center_all text_center"
      >
        <div id="small_CTA" className="d-2-12 t-1-13 m-1-13 text_center">
          <p className="white main_p">{sub} </p>   <a href={button_link}>

          <strong><p className="white main_p"> &nbsp;&nbsp;{button_text} &nbsp;
            <FontAwesomeIcon icon={faLongArrowAltRight} /></p></strong>

        </a>

        </div>
      </div>
    )
  }
}

export default SmallCTA
