import { Component } from 'react'
import './button.scss'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Button extends Component {
  render () {
    const text = this.props.text
    const link = this.props.link

    if (this.props.color === 'blue') {
      return (
        <div className="btn_container">
          <a
            className={this.props.classname ? this.props.classname : ''}
            href={link}
          >
            <div className="button background_blue">
              <span className="white">
                {text} &nbsp;
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </span>
            </div>
          </a>
        </div>
      )
    } else if (this.props.color === 'green') {
      return (
        <div className="btn_container">
          <a
            className={this.props.classname ? this.props.classname : ''}
            href={link}
          >
            <div className="button background_blue">
              <span className="white">{text}</span>
            </div>
          </a>
        </div>
      )
    } else if (this.props.color === 'grey') {
      return (
        <div className="btn_container">
          <a
            className={this.props.classname ? this.props.classname : ''}
            href={link}
          >
            <div className="button background_grey">
              <span className="blue">{text}</span>
            </div>
          </a>
        </div>
      )
    } else if (this.props.color === 'dblue') {
      return (
        <div className="btn_container">
          <a
            className={this.props.classname ? this.props.classname : ''}
            href={link}
          >
            <div className="button background_dblue">
              <span className="white">{text}</span>
            </div>
          </a>
        </div>
      )
    } else if (this.props.color === 'red') {
      // alert(this.props.clickable);
      return (
        <div className="btn_container">
          <a
            className={this.props.classname ? this.props.classname : ''}
            href={link}
          >
            <div
              className={
                this.props.clickable
                  ? 'button background_red '
                  : 'button background_darkred'
              }
            >
              <span className="white">{text}</span>
            </div>
          </a>
        </div>
      )
    } else {
      return (
        <div className="btn_container">
          <a
            className={this.props.classname ? this.props.classname : ''}
            href={link}
          >
            <div className="button background_white">
              <span className="blue">{text}</span>
            </div>
          </a>
        </div>
      )
    }
  }
}

export default Button
