import Cookies from "../../services/Cookies";
import "./WishlistMenuItem.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { API_URL } from "../../constraints";
import { JSX, useState } from "react";
import classNames from "classnames";
import noImage from '../../assets/images/noImage.png'

type WishlistMenuItemProps = {
    label?: string;
    name: string;
    url: string;
    logo?: string;
    likeId: number;
}
export default function WishlistMenuItem({label, name, logo, url, likeId}: WishlistMenuItemProps): JSX.Element {
    const token = Cookies.get('AUTH_TOKEN')
    const [dissolving, setDissolving] = useState<boolean>(false)
    const [deleting, setDeleting] = useState(false)
    const [deleted, setDeleted] = useState<boolean>(false)

    if (!token || deleted) {
        return (<></>)
    }

    const handleClick = () => {
        if (deleting) {
            return
        }

        if (token && likeId) {
            setDeleting(true)
            fetch(
                API_URL + `/api/likes/${likeId}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `${token}`,
                    },
                },
            ).then((res) => {
                if (res.status === 200) {
                    setDissolving(true)
                    setTimeout(() => setDeleted(true), 2000)
                }
            })
        }
    }

    return (
        <div className={classNames(
            "wishlist-menu__item",
            {
                "dissolve": dissolving
            }
        )}>
            <div className={"wishlist-menu__item__logo"}>
                {logo && (
                    <img alt={name} src={`${logo}?w=128&h=128&fit=inside&f=webp&q=100`} />
                )}
                {!logo && (
                    <img src={noImage} alt={name} />
                )}
            </div>

            <div className={"wishlist-menu__item__info"}>
                {label && (
                    <div className={"label"}>{label}</div>
                )}

                <div>
                    <a href={url}>{name}</a>
                </div>
            </div>

            <div className={classNames(
                "wishlist-menu__item__remove",
                {
                    "deleting": deleting
                }
            )}>
                <button aria-label={"Delete"} onClick={handleClick}>
                    <FontAwesomeIcon icon={faTimes} color={"#999999"} />
                </button>
            </div>
        </div>
    )
}