import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { JSX } from 'react'
import { DirectoryRoute, ProfileRoute, SearchRoute, ToolsRoute } from './routes/'

import landing from './pages/landing'
import story from './pages/story'
import listservice from './pages/listservice'
import listapp from './pages/listapp'
import privacy from './pages/privacy'
import toolfinder from './pages/tool-finder'
import terms from './pages/terms'
import contact from './pages/contact'

import General from './layouts/general'

import ScrollToTop from './actions/scrollToTop'
import NotFound from './pages/notFound'
import slugify from 'slugify'
import { API_URL } from "./constraints";

const Router = (): JSX.Element => {
    if (window && window.location && window.location.pathname) {
        // Check slug is correct format
        const getPathname = () => {
            return '/' + window.location.pathname
                .toLowerCase()
                .split('/')
                .filter(s => s)
                .map(s => slugify(s))
                .join('/')

        }
        const pathname = getPathname()
        if (window.location.pathname !== pathname) {
            window.location.replace(pathname)
        }
    }
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/sitemap.xml" exact
                       render={() => {
                           window.location.href = `${API_URL}/api/sitemap/index.xml`;
                           return null;
                       }} />
                <Route path="/tools" component={ToolsRoute} />
                <Route path="/directory" component={DirectoryRoute} />
                <Route path="/search" component={SearchRoute} />
                <Route path="/profile" component={ProfileRoute} />

                <Route path="/">
                    <General>
                        <ScrollToTop />
                        <Switch>
                            <Route path="/" exact component={landing} />
                            <Route path="/story" exact component={story} />
                            <Route path="/list-an-app" exact component={listapp} />
                            <Route
                                path="/list-a-cloud-expert"
                                exact
                                component={listservice}
                            />
                            <Route path="/terms-of-use" exact component={terms} />
                            <Route path="/contact-us" exact component={contact} />
                            <Route path="/privacy-policy" exact component={privacy} />
                            <Route path="/free-tool-finder" exact component={toolfinder} />
                            <Route render={() => <Redirect to="/" />} />
                            <Route component={NotFound} />
                        </Switch>
                    </General>
                </Route>
            </Switch>
        </BrowserRouter>
    )
}

export default Router
