const FormField = ({
  label,
  type,
  name,
  value,
  errors,
  handleInput,
  required = true,
}) => {
  const TagName = type === 'textarea' ? 'textarea' : 'input'

  return (
    <div>
      <label className="subtitle" htmlFor={name}>
        {label}
      </label>
      <div className="input-containers">
        <TagName
          type={type !== 'textarea' ? type : undefined}
          id={name}
          name={name}
          value={value}
          onChange={handleInput}
          required={required}
          className={errors[name] ? 'error-input' : ''}
          aria-label={label}
          autoComplete={type === 'email' ? 'email' : undefined}
        />
        <div className={errors[name] ? 'error-input' : 'none'}
             aria-live="polite">
          <span>{errors[name]}</span>
        </div>
      </div>
    </div>
  )
}

export default FormField