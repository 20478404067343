import React, { Component } from 'react'
import FilterMainOld from '../components/filter_main/filtermain_old'
import Button from '../components/button/button'
import Cookie from 'js-cookie'
import { Helmet } from 'react-helmet'
import { API_URL } from '../constraints'
import FormField from '../components/form_field/FormField'
import Recaptcha from '../components/form_field/Recaptcha'
import ToolsCTA from '../content/ToolsCTA'

const token = Cookie.get('AUTH_TOKEN') ? Cookie.get('AUTH_TOKEN') : null

class contact extends Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      message: '',
      contact: '',
      errors: [],
      loading: false,
      sent: false,
      recaptcha: false,
    }
    this.handleFormChange = this.handleFormChange.bind(this)
    this.submit = this.handleSubmit.bind(this)
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ loading: true })
    if (!this.state.recaptcha) {
      this.setState({ loading: false })
    } else {
      let payload = {
        'data': {
          'first_name': this.state.name,
          'message': this.state.message,
          'number': this.state.contact,
          'email': this.state.email,
        },
      }

      fetch(
        API_URL +
        '/api/contacts',
        {
          method: 'POST',
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        },
      )
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error('Something went wrong ...')
          }
        })
        .then((data) => {
          this.setState({ loading: false, sent: true })
        })
        .catch((error) => {
          this.setState({ saving: false })
          this.setState({ error: error })
          console.error(error)
        })
    }
  }

  handleFormChange (evt) {
    const value = evt.target.value
    this.setState({
      ...this.state,
      [evt.target.name]: value,
    })
  }

  render () {
    const { errors, name, contact, loading, sent } = this.state

    if (loading) {
      return (
        <div id="results_page">
          <FilterMainOld search={false} text={'Contact us'} />
          <div className="gc no-gap contact_Spinner">
            <div className="d-2-12 t-3-11 m-1-13 ">
              <svg className="splash-spinner" viewBox="0 0 50 50">
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            </div>
          </div>
          <ToolsCTA />
        </div>
      )
    }
    if (sent) {
      return (
        <div id="results_page">
          <Helmet>
            <title>Contact us - The Right Tool</title>
            <meta
              name="description"
              content="Get in touch with the team at The Right Tool here."
            />
          </Helmet>
          <FilterMainOld search={false} text={'Contact us'} />
          <div
            className="opacityfade contact_Spinner gc no-gap center_all content">
            <div className="d-4-10 t-3-11 m-2-12 ">
              <p className="main_p">Your message has been sent.</p>
            </div>
          </div>
          <ToolsCTA />
        </div>
      )
    }

    return (
      <div id="results_page">
        <FilterMainOld search={false} text={'Contact us'} />
        <div className="gc no-gap center_all content">
          <div className="d-4-10 t-3-11 m-2-12 ">
            <p className="main_p">Drop us a line.</p>
            <form onSubmit={this.handleSubmit}
                  className="relative form_container">
              <FormField
                label="Full Name"
                type="text"
                name="name"
                value={this.state.name}
                errors={this.state.errors}
                handleInput={this.handleFormChange}
              />

              <FormField
                label="Contact Number"
                type="tel"
                name="contact"
                value={this.state.contact}
                errors={this.state.errors}
                handleInput={this.handleFormChange}
              />

              <FormField
                label="Email"
                type="email"
                name="email"
                value={this.state.email}
                errors={this.state.errors}
                handleInput={this.handleFormChange}
              />

              <FormField
                label="Message"
                type="textarea"
                name="message"
                value={this.state.message}
                errors={this.state.errors}
                handleInput={this.handleFormChange}
              />

              <Recaptcha handleChange={
                (value) => this.setState({ recaptcha: value })
              } />

              <div className="input-containers">
                <div className="pointer">
                  <button type="submit">
                    {' '}
                    <Button text={'Submit'} color="blue" type="submit" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <ToolsCTA />
      </div>
    )
  }
}

export default contact
