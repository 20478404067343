import React, { useEffect, useState } from 'react'
import './search.scss'
import {
  convertAppArray,
  convertServiceArray,
} from '../../util/api_converters'
import { API_URL } from '../../constraints'
import ResultItem from './ResultItem'

const Result = (props) => {
  // const isvisible = this.props.visble
  const [data] = useState(props.data)
  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(true)

  const performSearch = async () => {
    const appSearchResults = fetch(API_URL +
      `/api/apps?filters[$or][0][name][$containsi]=${data}&filters[$or][1][subcategories][name][$containsi]=${data}&populate=*`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .then((responseData) => {
        return convertAppArray(responseData)
      })

    const serviceSearchResults = fetch(API_URL +
      `/api/services?filters[$or][0][name][$containsi]=${data}&filters[$or][1][locations][name][$containsi]=${data}&populate=*`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .then((responseData) => {
        return convertServiceArray(responseData)
      })

    const results =
      await Promise.all([appSearchResults, serviceSearchResults])
        .then(results => results.flat())
    setResults(results)
  }
  useEffect(() => {
    performSearch()
      .finally(() => setLoading(false))
  }, [])

  return (
    <div className="gc no-gap" id="SearchResultContainer">
      <div className="d-2-12 t-2-12 m-2-12 ">
        <div className="searchResults">
          {loading ? (
            <svg className="splash-spinner" viewBox="0 0 50 50">
              <circle
                className="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="5"
              ></circle>
            </svg>
          ) : (
            <div className="query">
              {' '}
              <span className="found">
                Found {results.length}{' '}
                {results.length > 1 ? 'results ' : 'result '}
                for
              </span>
              <span className="bold found"> '{data && data}'</span>
            </div>
          )}

          {results.map(r => <ResultItem key={r.id} {...r} />)}
        </div>
      </div>
    </div>
  )
}

export default Result
