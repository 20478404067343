import React from 'react'
import Menu from '../components/menu/menu'
import Footer from '../components/footer/footer'
// import Cookie from "js-cookie";
// const token = Cookie.get("token") ? true : false;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  return (
    <div className="min-height">
      <Menu transparent={false} />
      {children}
      <Footer />
    </div>
  )
};
