import db1 from "../assets/images/db1.png";
import db2 from "../assets/images/db2.png";
import { JSX } from "react";
import HomeCard from "../components/home_card/HomeCard";

export default function DigitalBoostAbout(): JSX.Element {
    return (
        <div
            id="digitalboost"
            className="gc no-gap center_all content background_supergrey"
        >
            <div className="d-2-12 t-2-12 m-2-12 text_center">
                <h2>
                    Learn more about the other{' '}
                    <span className="red">Digital Boost initiatives </span>
                </h2>

                <p className="main_p">
                    Digital Boost is the government funded initiative to help drive
                    digital transformation and success in the New Zealand economy.
                </p>
            </div>
            <div className="d-2-12 t-3-11 m-1-13 gc profile">
                <HomeCard title={'Learn the digital skills to boost your business'}
                          mainImg={db1}
                          href={"https://digitalboostalliance.nz/"}
                          label={"Get free training"}
                          copy={"‘Digital’ doesn’t have to be intimidating. It’s here to make life easy! So whatever stage you’re at, we’ll help you use digital to do business smarter."}
                />

                <HomeCard title={'Learn the digital skills to boost your business'}
                          mainImg={db2}
                          href={"https://mailtrack.io/trace/link/bfeeb19e9466a41170f97f169e4002976056e2fe?url=https%3A%2F%2Fwww.business.govt.nz%2Fdo-business-online%2Fdigital-boost&userId=4068589&signature=5d0594a8c967c5dc"}
                          label={"Watch the series"}
                          copy={"See how small business owners who have recently transformed their business by adopting digital tools and digital ways of working share their experiences to benefit other small business owners."}
                />
            </div>
        </div>
    )
}