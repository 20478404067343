import dotenv from 'dotenv'

dotenv.config()

const {
  REACT_APP_STRAPI_URL = 'https://api.righttool.co.nz',
  REACT_APP_ADMIN_URL = 'https://admin.righttool.co.nz',
  REACT_APP_SITE_URL = 'https://therighttool.co.nz',
  REACT_APP_COOKIE_DOMAIN = '',
  REACT_APP_COOKIE_SECURE = false,
} = process.env

export const API_URL = REACT_APP_STRAPI_URL
export const ADMIN_URL = REACT_APP_ADMIN_URL
export const SITE_URL = REACT_APP_SITE_URL

// Set to .therighttool.co.nz on prod
export const COOKIE_DOMAIN = REACT_APP_COOKIE_DOMAIN
export const COOKIE_SECURE = REACT_APP_COOKIE_SECURE