import Cookie from 'js-cookie'
import _ from 'lodash'
import {
  convertAppLikes,
  convertCategoriesList,
  convertCategory,
  convertIndustriesList,
  convertIndustry,
  convertServiceLikes,
  convertServicesList,
  convertSubCategoriesList,
  convertSubcategory,
} from './api_converters'
import { API_URL } from '../constraints'

const token = Cookie.get('AUTH_TOKEN') ? Cookie.get('AUTH_TOKEN') : null

export async function industries () {
  const response = await fetch(
    API_URL + '/api/industries', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

  return {
    data: convertIndustriesList((await response.json()).data),
  }
}

export async function categories () {
  const response = await fetch(
    API_URL + '/api/categories?populate=*', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

  return {
    data: convertCategoriesList((await response.json()).data),
  }
}

export async function addLike (params) {
  let payload = {
    data: {},
  }
  if (params.app && params.app.id) {
    payload.data.app = params.app.id
  }
  if (params.service && params.service.id) {
    payload.data.service = params.service.id
  }

  if (!payload.data.app && !payload.data.service) {
    return false
  }

  return await fetch(API_URL + '/api/likes', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then(response => response.json())
    .then(response => {
      return {
        data: {
          url: API_URL +
            `/api/likes/apps/${response.data.id}/`,
        },
      }
    })
    .catch(err => {
      console.error('Could not add like')
      console.debug(err)
      return false
    })
}

export async function removeLike (params) {
  const data = await fetch(
    API_URL + `/api/likes/${params.listing}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
    },
  )
  return {
    data,
  }
}

export async function getSubcategory (params) {
  const name = params.name.replace(/ /g, '-')
  const str = name.replace(/[()]/g, '')
  const response = await fetch(
    API_URL +
    `/api/subcategories?filters[slug][$eq]=${str}&populate=*`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

  return {
    data: convertSubcategory((await response.json()).data[0]),
  }
}

export async function getServices () {
  const response = await fetch(
    API_URL + '/api/services?populate=*', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

  return {
    data: convertServicesList((await response.json()).data),
  }
}

export async function getLikes () {
  if (!token) {
    return {
      data: [],
    }
  }

  const likes = _.concat(
    // Apps
    await fetch(
      API_URL + '/api/likes/apps/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`,
        },
      })
      .then(response => response.json())
      .then(response => convertAppLikes(response))
      .catch(() => []),

    // Services
    await fetch(
      API_URL + '/api/likes/services/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`,
        },
      })
      .then(response => response.json())
      .then(response => convertServiceLikes(response))
      .catch(() => []),
  )

  return {
    data: likes,
  }
}

export async function getIndustry (params) {
  const name = params.name.replace(/ /g, '-')
  //const url =     `${API_URL}/api/industries?filters[slug][$eq]=${name}&populate[apps][populate]=*`
  const url = `${API_URL}/api/industry/${name}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(response => {
      if (response) {
        console.log(response)
        return convertIndustry(response)
      }

      return null
    })

  return {
    data: response,
  }
}

export async function categoryAPI (params) {
  const name = params.name.replace(/ /g, '-')
  const str = name.replace(/[()]/g, '')

  const subCategoriesResponse = await fetch(
    API_URL +
    `/api/subcategories?filters[category][slug]=${str}&populate[category][populate]=*&populate[apps][populate]=logo`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(responseData => {
      if (responseData && responseData.data) {
        return responseData.data
      }
    })

  const categoryResponse = await fetch(
    API_URL +
    `/api/categories?filters[slug][$eq]=${str}&pagination[start]=0&pagination[limit]=${params.limit}&populate[services][populate]=*`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(responseData => {
      if (responseData && responseData.data && responseData.data[0]) {
        return responseData.data[0]
      }
    })

  const convertedSubCategories = convertSubCategoriesList(subCategoriesResponse,
    parseInt(params.limit))
  const convertedCategory = convertCategory(categoryResponse,
    convertedSubCategories)

  return {
    data: convertedCategory,
  }
}
