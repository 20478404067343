import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class toggle extends Component {
  constructor (props) {
    super(props)
    this.state = {
      toggle: false,
      category: '',
      changeTo: window.location.pathname,
    }
  }

  componentDidMount () {
    this.checkURL()
    this.updateChangeTo()
  }

  updateChangeTo () {
    this.setState({
      changeToApps: this.state.changeTo.replace('apps', 'cloud-experts'),
    })
    this.setState({
      changeToServices: this.state.changeTo.replace('cloud-experts', 'apps'),
    })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.url !== this.props.url) {
      this.checkURL()
      this.setState(
        {
          changeTo: window.location.pathname.replace(
            prevProps.url.split('/').slice(-2)[0],
            this.props.url.split('/').slice(-2)[0],
          ),
        },
        () => {
          this.updateChangeTo()
        },
      )
    }
  }

  checkURL () {
    if (window.location.href.indexOf('apps') > -1) {
      this.setState({ toggle: false })
    } else {
      this.setState({ toggle: true })
    }
  }

  Toggle = () => {
    this.setState(prevState => ({
      toggle: !prevState.toggle,
    }))
  }

  render () {
    return (
      <div className="gc no-gap center_all toggleListingTypes">
        <div
          className={`d-1-7 t-1-7 m-1-7 text_center ${
            !this.state.toggle ? '' : 'background_grey'
          }`}
        >
          <Link to={`${this.state.changeToServices}`}>
            {' '}
            <span
              onClick={this.Toggle}
              className={`${!this.state.toggle ? '' : 'blue pointer '}`}
            >
              Apps
            </span>
          </Link>
        </div>
        <div
          className={`d-7-13 t-7-13 m-7-13 text_center ${
            this.state.toggle ? '' : 'background_grey'
          }`}
        >
          <span
            onClick={this.Toggle}
            className={`${this.state.toggle ? '' : 'blue pointer '}`}
          >
            {' '}
            <Link to={`${this.state.changeToApps}`}>Cloud Experts</Link>
          </span>
        </div>
      </div>
    )
  }
}

export default toggle
