import React, { Component } from 'react'
import Menu from '../components/menu/menu'
import Footer from '../components/footer/footer'
import FilterMain from '../components/filter_main/filter_main'
import Toggle from '../components/toggle/toggle'

class results extends Component {
  constructor (props) {
    super(props)
    this.state = {
      directory: '',
      message: '',
      urlstate: '',
    }
  }

  callbackFunction = (childData, url) => {
    this.setState({
      message: childData,
      urlstate: url,
    })
  }

  componentDidMount () {
    if (this.state.childData === undefined) {
      this.setState({
        message: window.location.pathname.split('/').slice(-1).join('/'),
      })
    }
  }

  render () {
    return (
      <div className="min-height">
        <Menu transparent={false} />
        <a className="skiplink" href="#maincontentskip">
          Skip To Content
        </a>

        <div id="results_page" name={this.state.message}>
          <FilterMain
            parentParentCallback={this.callbackFunction}
            homepagepass={this.state.message}
            search={true}
            text={'Here are the right tools for'}
          />
          <Toggle url={this.state.urlstate} />
          {/* 
                {React.cloneElement(this.props.children, { message: this.state.message })} */}
          {this.props.children}
        </div>
        <Footer />
      </div>
    )
  }
}

export default results
