import React, { Component } from 'react'
import FilterMainOld from '../components/filter_main/filtermain_old'
import { Helmet } from 'react-helmet'
import ToolsCTA from '../content/ToolsCTA'

function PrivacyText (props) {
  return (
    <div className="gc no-gap center_all content">
      <div className="d-3-11 t-3-11 m-2-12 ">
        <p className="termTitle dark">Introduction</p>

        <p>
          The Technology Users Association (on behalf of the Digital Boost
          Alliance) (we, us, our) complies
          with the New Zealand Privacy Act 2020 (the Act) when dealing with
          personal information. Personal information is information about an
          identifiable individual (a natural person).
        </p>

        <p>
          This policy sets out how we will collect, use, disclose and protect
          your personal information.
        </p>

        <p>
          This policy does not limit or exclude any of your rights under the
          Act. If you wish to seek further information on the Act, see
          www.privacy.org.nz.
        </p>

        <p className="termTitle">Changes to this policy</p>

        <p>
          We may change this policy by uploading a revised policy onto the
          website. The change will apply from the date that we upload the
          revised policy.
        </p>

        <strong>
          <p>This policy was last updated on November 19th 2020.</p>
        </strong>

        <p className="termTitle">
          Where do we collect personal information about you from
        </p>

        <strong>
          <p>We collect personal information about you:</p>
        </strong>

        <li>
          when you provide that personal information to us, including via the
          website and any related service, through any registration or
          subscription process, through any contact with us (for example,
          telephone call or email), or when you buy or use our services and
          products{' '}
        </li>
        <li>
          from third parties where you have authorised this or the information
          is publicly available.
        </li>

        <p>
          Where possible, we will collect personal information from you
          directly.
        </p>

        <p></p>

        <p className="termTitle">How we use your personal information</p>

        <strong>
          <p>We will use your personal information:</p>
        </strong>

        <p>
          <li>to verify your identity</li>
          <li>to provide services and products to you</li>
          <li>
            to market our services and products to you, including contacting you
            electronically (for example, by text or email for this purpose)
          </li>
          <li>to improve the services and products that we provide to you</li>
          <li>to undertake credit checks of you (if necessary)</li>
          <li>to respond to communications from you, including a complaint</li>
          <li>
            to conduct research and statistical analysis (on an anonymised
            basis)
          </li>
          <li>
            to protect and/or enforce our legal rights and interests, including
            defending any claim
          </li>
          <li>for any other purpose authorised by you or the Act.</li>
        </p>

        <p></p>

        <p className="termTitle">Disclosing your personal information</p>

        <strong>
          <p>We may disclose your personal information to:</p>
        </strong>

        <p>
          <li>
            any business that supports our services and products, including any
            person that hosts or maintains any underlying IT system or data
            centre that we use to provide the website or other services and
            products
          </li>
          <li>
            a person who can require us to supply your personal information (for
            example, a regulatory authority)
          </li>
          <li>
            any other person authorised by the Act or another law (for example,
            a law enforcement agency)
          </li>
          <li>any other person authorised by you.</li>
        </p>

        <p>
          A business that supports our services and products may be located
          outside New Zealand. This may mean your personal information is held
          and processed outside New Zealand.
        </p>

        <p></p>

        <p className="termTitle">Protecting your personal information</p>

        <p>
          We will take reasonable steps to keep your personal information safe
          from loss, unauthorised activity, or other misuse.
        </p>

        <p></p>

        <p className="termTitle">
          Accessing and correcting your personal information
        </p>

        <p>
          Subject to certain grounds for refusal set out in the Act, you have
          the right to access your readily retrievable personal information that
          we hold and to request a correction to your personal information.
          Before you exercise this right, we will need evidence to confirm that
          you are the individual to whom the personal information relates.
        </p>

        <p>
          In respect of a request for correction, if we think the correction is
          reasonable and we are reasonably able to change the personal
          information, we will make the correction. If we do not make the
          correction, we will take reasonable steps to note on the personal
          information that you requested the correction.
        </p>

        <p>
          If you want to exercise either of the above rights, email us at{' '}
          <a
            href="mailto:find@therighttool.co.nz">find@therighttool.co.nz.</a>{' '}
          Your email should provide evidence of who you are and set out the
          details of your request (for example, the personal information, or the
          correction, that you are requesting).
        </p>

        <p>
          We may charge you our reasonable costs of providing to you copies of
          your personal information or correcting that information.
        </p>

        <p></p>

        <p className="termTitle">Internet use</p>

        <p>
          While we take reasonable steps to maintain secure internet
          connections, if you provide us with personal information over the
          internet, the provision of that information is at your own risk.
        </p>

        <p>
          If you post your personal information on the website&rsquo;s via a
          public review, forum or message box, you acknowledge and agree that
          the information you post is publicly available.
        </p>

        <p>
          If you follow a link on our website to another site, the owner of that
          site will have its own privacy policy relating to your personal
          information. We suggest you review that site&rsquo;s privacy policy
          before you provide personal information.
        </p>

        <p></p>

        <p className="termTitle">Cookies</p>

        <p>
          Browser or ‘web’ cookies are small text files that are sent by a
          website and stored on your computer's hard drive to collect
          information about how you browse the website.
        </p>

        <p>Cookies are used to:</p>

        <p>
          measure how you use the website so it can be updated and improved
          based on your needs
          <br />
          remember the notifications you’ve seen so that we don’t show them to
          you again
        </p>

        <p>
          You can read Google’s privacy statement at the Google Safety Center.
        </p>

        <p>
          You can manually disable cookies at any time. Check your browser's
          'Help' to find out how (disabling cookies will not affect your ability
          to use this website).
        </p>
        <p>Data collected may include:</p>
        <li>your IP address</li>
        <li>the search terms you used on this site</li>
        <li>
          the pages you accessed on this site and the links you clicked on
        </li>
        <li>the date and time you visited this site</li>
        <li>
          the referring site (if any) from which you clicked to reach this site
        </li>
        <li>the device you used to access this site</li>
        <li>your operating system (for example, Windows 10, Mac OS X)</li>
        <li>
          the type of web browser you used to access this site (for example,
          Internet Explorer, Google Chrome)
        </li>
        <li>
          other things like your screen resolution and thelanguage setting of
          your browser.
        </li>
        <p className="termTitle">Contacting us</p>

        <p>
          If you have any questions about this privacy policy, our privacy
          practices, or if you would like to request access to, or correction
          of, your personal information, you can contact us at{' '}
          <a
            href="mailto:find@therighttool.co.nz">find@therighttool.co.nz.</a>{' '}
          <br />
        </p>
      </div>
    </div>
  )
}

class privacy extends Component {
  render () {
    return (
      <div id="results_page">
        <Helmet>
          <meta
            name="description"
            content="We take your data seriously. Find out more about our privacy policy here."
          />
          <title>Privacy Policy - The Right Tool</title>
        </Helmet>
        <FilterMainOld search={false} text={'Privacy Policy'} />
        <PrivacyText />
        <ToolsCTA />
      </div>
    )
  }
}

export default privacy
