import React, { Component } from 'react'
import FilterMain from '../components/filter_main/filter_main'
import { Helmet } from 'react-helmet'

//swiper
import SwiperCore, {
  Autoplay,
  EffectCoverflow,
  Navigation,
  Pagination,
} from 'swiper/core'
import 'swiper/swiper.min.css'
import { SITE_URL } from '../constraints'
import LandingCards from '../content/LandingCards'
import DigitalBoostAbout from '../content/DigitalBoostAbout'
import ImageCTA from '../components/cta/ImageCTA'
// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation, EffectCoverflow])

class landing extends Component {
  constructor (props) {
    super(props)
    this.state = {
      directory: '',
      message: '',
      urlstate: '',
    }
  }

  callbackFunction = (childData, url) => {
    this.setState({
      message: childData,
      urlstate: url,
    })
  }

  render () {
    return (
      <div className="opacityfade">
        <Helmet>
          <meta
            name="description"
            content="Find the right tools to help you run your small business. Quickly find peer reviewed apps and software to help to manage and grow your business. Get started."
          />
          <meta
            property="og:image"
            content={`${SITE_URL}/TheRightTool.jpg`}
          />
          <title>The Right Tool - Aotearoa’s leading app marketplace</title>
        </Helmet>
        <FilterMain
          search={true}
          text={[
            <React.Fragment key={'filter-main-landing'}>
              <span
                className="background_gradient">Find the right tools </span>
              <br></br>
              for your business
            </React.Fragment>,
          ]}
          parentParentCallback={this.callbackFunction}
          homepagepass={this.state.message}
        />

        <LandingCards />

        <ImageCTA />
        <DigitalBoostAbout />
      </div>
    )
  }
}

export default landing
