import React, { Component } from 'react'
import './service_cta.scss'
import Button from '../button/button'
import Listing from '../listing/listing'
import _ from 'lodash'
import { convertServicesList } from '../../util/api_converters'
import { API_URL } from '../../constraints'

class ServiceCta extends Component {
  constructor (props) {
    super(props)
    this.state = {
      featured: [],
      loading: false,
    }
  }

  componentDidMount () {
    this.fetchIndustriesListings()
  }

  fetchIndustriesListings = (e) => {
    fetch(
      API_URL + '/api/services?populate=*', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .then((response) => _.sampleSize(response, 4))
      .then((responseData) => {
        const data = convertServicesList(responseData)
        this.setState({
          featured: data,
          loading: true,
        })
      })
      .catch((error) => {
        this.setState({ error: error })
        console.error(error)
      })
  }

  render () {
    if (this.state.loading) {
      const main_text = this.props.text
      const sub = this.props.sub
      const button_text = this.props.button_text
      const button_link = this.props.button_link
      const { featured } = this.state

      return (
        <div id="service_cta" className={`background_blue center_all content`}>
          <div className="gc no-gap">
            <div className="d-4-10 t-3-11 m-2-12 text_center">
              <span className="white">Featured Cloud Experts</span>
              <h2 className="white">{main_text}</h2>
              <p className="white main_p">{sub}</p>
            </div>
          </div>
          <div className="gc no-gap center_all servicecta_container">
            <div className="d-2-12 t-3-11 m-1-13 gc profile">
              <Listing data={featured[0]} />
              <Listing data={featured[1]} />
            </div>
            <div className="d-2-12 t-3-11 m-1-13 gc profile">
              <br></br> <br></br>
            </div>
            <div className="d-2-12 t-3-11 m-1-13 gc profile">
              <Listing data={featured[2]} />
              <Listing data={featured[3]} />
            </div>
          </div>
          <div className="gc no-gap">
            <div className="d-4-10 t-3-11 m-1-13 text_center">
              <Button text={button_text} color="white" link={button_link} />
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default ServiceCta
