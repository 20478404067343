import ReCAPTCHA from 'react-google-recaptcha'
import React from 'react'

const Recaptcha = ({ handleChange }) => {
  return (
    <div className="input-containers">
      <ReCAPTCHA
        sitekey="6Lebb0MaAAAAAClMRoyZowsTBi6yc291nfE7jVU2"
        onChange={handleChange}
      />
    </div>
  )
}

export default Recaptcha
