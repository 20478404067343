import React, { useState } from 'react'

const Toggle = (props) => {
  const [active, setActive] = useState(props.activeApps)
  const ToggleFunction = (arg) => {
    if ((active && arg === 'apps') || (!active && arg === 'services'))
      return null
    setActive(!active)
    props.change(!active)
  }

  return (
    <div className="gc no-gap center_all toggleListingTypes">
      <div
        className={`d-1-7 t-1-7 m-1-7 text_center ${
          active ? '' : 'background_grey'
        }`}
      >
        <span
          onClick={() => ToggleFunction('apps')}
          className={`${active ? '' : 'blue pointer '}`}
        >
          Apps
        </span>
      </div>
      <div
        className={`d-7-13 t-7-13 m-7-13 text_center ${
          !active ? '' : 'background_grey'
        }`}
      >
        <span
          onClick={() => ToggleFunction('services')}
          className={`${!active ? '' : 'blue pointer '}`}
        >
          {' '}
          Cloud Experts
        </span>
      </div>
    </div>
  )
}
export default Toggle
