import React, { Component, useState } from 'react'
import Listing from '../components/listing/listing'
import ReactStars from 'react-rating-stars-component'
import Button from '../components/button/button'
import Reviews from '../components/reviews/reviews'
import Team from '../components/team/team'
import SmallCTA from '../components/small_cta/small_cta'
import noImage from '../assets/images/noImage.png'
import tick from '../assets/images/tick.png'
import Offer from '../components/offer/offer'
import ReactModal from 'react-modal'
import {
  faEnvelope,
  faGlobe,
  faLongArrowAltLeft,
  faPhone,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cookie from 'js-cookie'
import { Helmet } from 'react-helmet'
import { convertPostReview, convertService } from '../util/api_converters'
import { ADMIN_URL, API_URL } from '../constraints'
import WishlistButton from '../components/wishlist/WishlistButton'

const token = Cookie.get('AUTH_TOKEN') ? Cookie.get('AUTH_TOKEN') : null

function ReviewContainer (props) {
  const reviews = props.reviews
  const [visibleBlogs, setVisibleBlogs] = useState(2)
  var handleOpenModal = props.handleOpenModal

  const handleClick = () => {
    setVisibleBlogs(prevVisibleBlogs => prevVisibleBlogs + 2)
  }

  const reviewMap = reviews.slice(0, visibleBlogs).map((review, i) => {
    return <Reviews data={review} key={`review-${i}`} />
  })
  return (
    <>
      <div className="d-4-12 t-2-12 m-2-12  app_container">
        <div className="split_between reviewContainer">
          <h4>Reviews</h4>
          <p className={reviews.length < 1 ? 'main_p' : 'hide'}>No reviews
            yet</p>
          <div className="pointer"
               onClick={token ? () => handleOpenModal() : ''}>
            {' '}
            <Button
              text={token ? 'Write a Review' : 'Sign up to leave a Review'}
              color="white"
              link={
                token
                  ? null
                  : ADMIN_URL
              }
            />{' '}
          </div>
        </div>
      </div>
      <div className="d-4-12 t-2-12 m-2-12 gc ">{reviewMap}</div>
      <div
        onClick={handleClick}
        className="d-4-12 t-2-12 m-2-12 text-center flex center_all padding20"
      >
        <div className={`${reviews.length <= visibleBlogs ? 'hide' : ''} `}>
          {' '}
          {reviews.length > 2 && (
            <Button
              text={'Show more'}
              color="white"
              className={`${reviews.length <= visibleBlogs ? 'hide' : ''} `}
            />
          )}
        </div>
      </div>
    </>
  )
}

function MeetTheTeam (props) {
  const team = props.team
  return (
    <>
      <div className="d-4-12 t-2-12 m-2-12">
        <div className="split_between reviewContainer">
          <h4>Meet the team</h4>
          <p className={team.length < 1 ? 'main_p' : 'hide'}>No team members
            yet</p>
        </div>
      </div>
      <div className="d-4-12 t-2-12 m-2-12 team_member_container">
        {team.map((member, i) => {
          return <Team data={member} key={`team_${i}`} />
        })}
      </div>
      <div
        className="d-4-12 t-2-12 m-2-12 text-center flex center_all padding20">
        {team.length > 4 ? <Button text={'Show more'} color="white" /> : ''}
      </div>
    </>
  )
}

class profileservices extends Component {
  constructor (props) {
    super(props)
    this.state = {
      industries: [],
      data: [],
      errorsreview: [],
      reviews: [],
      team: [],
      apps: [],
      loading: true,
      industries_served: [],
      showModal: false,
      MessageshowModal: false,
      review_rating: 0,
      reviewSent: false,
      sendingReview: false,
      review_content: '',
      url: '',
      full_name: '',
      email: '',
      message: '',
      contact: '',
      app_likes: [],
      wishlistAdded: false,
      loadingMessage: false,
      sentMessage: false,
      visibleApps: 4,
    }
    this.handleFormChange = this.handleFormChange.bind(this)
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleSendMessageOpenModal = this.handleSendMessageOpenModal.bind(
      this,
    )
    this.handleSendMessageCloseModal = this.handleSendMessageCloseModal.bind(
      this,
    )
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  handleClickMoreApps = () => {
    this.setState({
      visibleApps: this.state.visibleApps + 4,
    })
  }

  handleOpenModal () {
    this.setState({ showModal: true })
  }

  handleSendMessageOpenModal () {
    this.setState({ MessageshowModal: true })
  }

  handleSendMessageCloseModal () {
    this.setState({ MessageshowModal: false })
  }

  handleRating (rating) {
    this.setState({ review_rating: rating })
  }

  handleCloseModal () {
    this.setState({ showModal: false })
  }

  handleFormChange (evt) {
    const value = evt.target.value
    this.setState({
      ...this.state,
      [evt.target.name]: value,
    })
  }

  async componentDidMount () {
    if (this.props.match.params.handle) {
      this.setState({ name: this.props.match.params.handle }, async () => {
        try {
          this.fetchListing()
        } catch (error) {
          this.setState({ error: error })
          console.error(this.state.error)
        }
      })
    }
  }

  fetchListing = e => {
    const fetchUrl = API_URL +
      `/api/service/${this.state.name}`
    fetch(fetchUrl,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(responseData => {
        const data = convertService(responseData)
        this.setState({
          data: data,
          reviews: data.reviews,
          industries_served: data.industries,
          apps: data.apps,
          team: data.team,
          loading: false,
        })

        for (let i = 0; i < this.state.app_likes.length; i++) {
          const element = this.state.app_likes[i]
          if (element.listing.url === data.url) {
            this.setState({ wishlistAdded: true, url: element.url })
          }
        }
      })
      .catch(error => {
        this.setState({ error: error })
        console.error(this.state.error)
      })
  }

  postReview = (e) => {
    fetch(API_URL + `/api/reviews/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        data: {
          type: 'SERVICE',
          service: this.state.data.id,
          rating: this.state.review_rating,
          comment: this.state.review_content,
          note: this.state.review_content,
        },
      }),
    })
      .then((response) => response.json())
      .then((responseData) => {
        const data = convertPostReview('services', responseData)
        let dataarry = []
        if (data.url) {
          this.setState({ sendingReview: false, reviewSent: true })
        } else {
          for (const [key, value] of Object.entries(data)) {
            dataarry.push(`${key}: ${value}`)
          }
          this.setState({
            errorsreview: dataarry,
          })
        }
      })
      .catch(error => {
        this.setState({ error: error })
        console.error(this.state.error)
      })
  }

  handleSubmit = event => {
    this.setState({ loadingMessage: true })
    event.preventDefault()
    let createdBy = this.state.data.created_by.replace(/\/$/, '')
    createdBy = createdBy.replace(/^(.*[\\\/])/g, '')

    let payload = {
      'data': {
        'created_by': createdBy,
        'first_name': this.state.name,
        'message': this.state.message,
        'number': this.state.contact,
        'email': this.state.email,
      },
    }

    fetch(
      API_URL + '/api/contacts',
      {
        method: 'POST',
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      },
    )
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Something went wrong ...')
        }
      })
      .then(data => {
        this.setState({ loadingMessage: false, sentMessage: true })
      })
      .catch(error => {
        this.setState({ saving: false })
        this.setState({ error: error })
      })
  }

  goBack () {
    window.history.back()
  }

  render () {
    const {
      reviews,
      data,
      industries_served,
      sentMessage,
      loadingMessage,
      apps,
      loading,
      team,
      reviewSent,
      sendingReview,
    } = this.state

    var handleOpenModal = this.handleOpenModal
    if (loading) {
      return (
        <div id="profile">
          <div className="gc no-gap profileHeader">
            <div className="d-2-12 2-12 m-1-13 ">
              <a onClick={this.goBack}>
                <div className="goback">
                  <span className="white pointer">
                    <FontAwesomeIcon icon={faLongArrowAltLeft} /> Go Back
                  </span>
                </div>
              </a>
            </div>
            <div className="d-2-12 t-2-12 m-1-13 ">
              <div id="profilePictureContainer"
                   className="d-2-12 t-2-12 m-1-13 ">
                <div className="profilepicture">
                  <img
                    src={data.logo ? data.logo : noImage}
                    alt="The listing logo"
                    itemProp="logo"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div className="gc no-gap splashscreen">
            <div className="d-2-12 t-3-11 m-1-13 ">
              <svg className="splash-spinner" viewBox="0 0 50 50">
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div id="profile" itemScope itemType="http://schema.org/Organization">
          <Helmet>
            <title>{data.name} - The Right Tool - Cloud Expert Directory</title>
            <meta
              name="description"
              content={`Read real reviews for ${data.name} - ${data.short_description} `}
            />
          </Helmet>
          <a className="skiplink" href="#profile">
            Skip To Content
          </a>
          <ReactModal
            className="modal gc "
            isOpen={this.state.MessageshowModal}
            contentLabel="Minimal Modal Example"
            styles={{}}
            id="newMessage"
          >
            <div className="form_container d-1-13 ">
              <div className="review_modal_inner">
                <div></div>
                <div
                  onClick={this.handleSendMessageCloseModal}
                  className="pointer fasvg"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </div>
            </div>
            {sentMessage ? <h4 className="thankyou">Message sent.</h4> : null}
            {loadingMessage ? (
              <svg className="splash-spinner" viewBox="0 0 50 50">
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            ) : null}
            {!loadingMessage && !sentMessage ? (
              <>
                <div className="form_container d-1-13 ">
                  <img
                    src={data.logo ? data.logo : noImage}
                    alt="The listing logo"
                  ></img>
                  <div>
                    <span className="subline">Full Name</span>
                    <input
                      value={this.name}
                      onChange={this.handleFormChange}
                      name="name"
                    />
                  </div>
                  <div className="inputSplit">
                    <div>
                      <span className="subline">Email</span>
                      <input
                        value={this.email}
                        onChange={this.handleFormChange}
                        name="email"
                      />
                    </div>
                    <div>
                      <span className="subline">Contact Number</span>
                      <input
                        value={this.contact}
                        onChange={this.handleFormChange}
                        name="contact"
                      />
                    </div>
                  </div>
                  <div>
                    <span className="subline">How can {data.name} help?</span>
                    <textarea
                      rows="4"
                      value={this.message}
                      onChange={this.handleFormChange}
                      name="message"
                    />
                  </div>
                </div>
                {' '}
                <div className="form_container d-1-13">
                  <div className="split_between">
                    <div
                      className="pointer"
                      onClick={this.handleSendMessageCloseModal}
                    >
                      <Button text={'Cancel'} color="grey" />
                    </div>
                    <div className="pointer" onClick={this.handleSubmit}>
                      <Button
                        classname="sendmodaltag"
                        text={'Confirm'}
                        color="blue"
                      />
                    </div>
                  </div>
                </div>
                {' '}
              </>
            ) : null}
          </ReactModal>

          <ReactModal
            className="modal gc "
            isOpen={this.state.showModal}
            contentLabel="Minimal Modal Example"
            styles={{}}
          >
            <div className="form_container d-1-13 ">
              <div className="review_modal_inner">
                <div></div>
                <div onClick={this.handleCloseModal} className="pointer fasvg">
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </div>
            </div>
            {reviewSent ? (
              <h4 className="thankyou">Thank you for the review</h4>
            ) : null}
            {sendingReview ? (
              <svg className="splash-spinner" viewBox="0 0 50 50">
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            ) : null}
            {!sendingReview && !reviewSent ? (
              <>
                <div className="form_container d-1-13 ">
                  <img
                    src={data.logo ? data.logo : noImage}
                    alt="The listing logo"
                  ></img>
                  <h4>{data.name} Review</h4>
                  <span className="darkgrey">
                    Leave a review - please note these go through an approval
                    process.
                  </span>

                  <div>
                    <ReactStars
                      value={this.state.review_rating}
                      onChange={rating => this.handleRating(rating)}
                      size={30}
                      activeColor={'#FAAA00'}
                      color={'#767676'}
                    />
                  </div>
                  <div>
                    <span className="subline">Review</span>
                    <textarea
                      rows="4"
                      value={this.review_content}
                      onChange={this.handleFormChange}
                      name="review_content"
                      // className={errors.username ? "errorinputfield" : ""}
                    />
                  </div>
                  <div className="errorbox">
                    {' '}
                    {this.state.errorsreview.map((x, i) => (
                      <li key={`e_${i}`}>{x}</li>
                    ))}{' '}
                  </div>
                </div>
                {' '}
                <div className="form_container d-1-13">
                  <div className="split_between">
                    <div className="pointer" onClick={this.handleCloseModal}>
                      <Button text={'Cancel'} color="grey" />
                    </div>
                    <div className="pointer" onClick={this.postReview}>
                      <Button
                        classname="sendmodaltag"
                        text={'Confirm'}
                        color="blue"
                      />
                    </div>
                  </div>
                </div>
                {' '}
              </>
            ) : null}
          </ReactModal>
          <div className="gc no-gap profileHeader">
            <div className="d-2-12 t-2-12 m-2-12 ">
              <a onClick={this.goBack}>
                <div className="goback">
                  <span className="white pointer">
                    <FontAwesomeIcon icon={faLongArrowAltLeft} /> Go Back
                  </span>
                </div>
              </a>
            </div>
            <div
              id="profilePictureContainer"
              className="d-2-12 t-2-12 m-1-13 verfied_or_featured "
            >
              <div className="split_between">
                <div className="profilepicture">
                  <img
                    itemProp="logo"
                    src={data.logo ? data.logo : noImage}
                    alt="The listing logo"
                  ></img>
                </div>
                <div className="m-hide">
                  <img src={tick} alt="The listing logo"></img>
                  <span>
                    {data.featured ? 'Featured' : 'Verified '} Cloud Expert
                  </span>
                </div>
              </div>
            </div>
          </div>
          {data.special_offers.length > 0 && (
            <Offer data={data.special_offers} />
          )}

          <div
            className={`gc no-gap ${
              data.special_offers.length > 0 ? 'specialoffercontent' : 'content'
            } opacityfade`}
          >
            <div className="d-4-12 t-2-12 m-2-12 profile_mobile ">
              <span className="industry">
                {' '}
                {this.state.industries_served.map((industry, i) => {
                  let length = this.state.industries_served.length

                  if (i > 2) {
                    return null
                  } else {
                    if (2 === i) {
                      if (length > 2) {
                        return `${industry.name}...`
                      }
                      return `${industry.name}`
                    } else {
                      return `${industry.name}, `
                    }
                  }
                })}
              </span>
              <div className="profile_position">
                <div className="heartPosition">
                  <h1 className="inline-block " itemProp="name">
                    {data.name}
                  </h1>
                  <WishlistButton listingId={data.id}
                                  listingType={'service'} />
                </div>
                <div className="d-hide t-hide">
                  <ReactStars
                    value={data.rating ? data.rating : 0}
                    onChange={rating => this.handleRating(rating)}
                    size={30}
                    edit={false}
                    activeColor={'#FAAA00'}
                    color={'#767676'}
                  />
                  <span className="darkgrey"> {reviews.length} Reviews</span>
                </div>

                <div id="profileHeaderBtns" className="btn_container">
                  <div
                    className="pointer"
                    onClick={this.handleSendMessageOpenModal}
                  >
                    <Button text={'Send Message'} color="blue" />
                  </div>
                </div>
              </div>
              <div>
                <div className="m-hide starsnew">
                  {' '}
                  <ReactStars
                    value={data.rating ? data.rating : 0}
                    size={30}
                    edit={false}
                    activeColor={'#FAAA00'}
                    onChange={rating => this.handleRating(rating)}
                    color={'#767676'}
                  />
                  <span className="darkgrey"> {reviews.length} Reviews</span>
                </div>
              </div>
            </div>
            <div className="d-4-12 t-2-12 m-2-12 inner">
              <h4>About</h4>

              <p className="main_p" itemProp="description">
                {data.long_description}
              </p>
              <p className="main_p pricing_p">{data.pricing}</p>
            </div>
            <div id="detailContainer" className="d-4-12 t-2-12 m-2-12 inner">
              <h4>Details</h4>
              <a
                itemProp="email"
                href={'mailto:' + data.email}
                className={`blue main_p  ${data.email ? '' : 'hide'}`}
              >
                <FontAwesomeIcon icon={faEnvelope} />
                {data.email}
              </a>
              <p></p>
              <a
                itemProp="telephone"
                href={'tel:' + data.phone}
                className={`blue main_p  ${data.phone ? '' : 'hide'}`}
              >
                <FontAwesomeIcon icon={faPhone} />
                {data.phone}
              </a>
              <p></p>
              <a
                itemProp="website"
                target="_blank"
                rel="noopener noreferrer"
                href={data.link}
                className={`blue main_p freetrial  ${data.link ? '' : 'hide'}`}
              >
                <FontAwesomeIcon icon={faGlobe} />
                {data.link}
              </a>
            </div>
            <div className="d-4-12 t-2-12 m-2-12 inner">
              <h4>Industries served</h4>
              <p className="main_p">
                {industries_served.map((industry, i) => {
                  let length = industries_served.length
                  if (length === i + 1) {
                    return `${industry.name}`
                  } else {
                    return `${industry.name}, `
                  }
                })}
              </p>
            </div>
            <div className="d-4-12 t-2-12 m-2-12 ">
              <h4>Apps we provide services for</h4>
            </div>
            <div className="d-4-12 t-2-12 m-1-13 app_container gc">
              {apps.slice(0, this.state.visibleApps).map(function (listing, i) {
                let URLvar = listing.url.replace(/\/$/, '')
                URLvar = URLvar.replace(/^(.*[\\\/])/g, '')
                return (
                  <Listing key={i} data={listing}
                           link={`/profile/app/${URLvar}`} />
                )
              })}

              <div
                className="d-2-12 t-2-12 m-2-12 text-center flex center_all padding20"
                onClick={this.handleClickMoreApps}
              >
                {this.state.visibleApps < this.state.apps.length && (
                  <Button text={'Show more'} color="white" />
                )}
              </div>
            </div>

            <ReviewContainer
              handleOpenModal={handleOpenModal.bind(this)}
              reviews={reviews}
            />
            <MeetTheTeam
              handleOpenModal={handleOpenModal.bind(this)}
              team={team}
            />
          </div>

          <SmallCTA
            sub={'Do you provide a software service?'}
            button_text={'List Your Cloud Expert Profile'}
            button_link={'/list-a-cloud-expert'}
          />
        </div>
      )
    }
  }
}

export default profileservices
