import React, { useState } from 'react'
import './search.scss'
import Button from '../button/button'

const formSubmit = (e, query) => {
  e.preventDefault()
  window.location.href = `/search/results?search=${query}`
}
const Search = props => {
  const defaultQuery = new URLSearchParams(window.location.search).get('search')
  const [query, setQuery] = useState(defaultQuery ?? '')
  return (
    <div className="gc no-gap searchWrapper">
      <div className="searchContainer d-2-12 t-2-12 m-2-12 ">
        <form id="search" onSubmit={e => formSubmit(e, query)}>
          <input
            id="searchInputField"
            value={query}
            onChange={e => setQuery(e.target.value)}
          ></input>
        </form>

        <Button
          text={`Search`}
          color="blue"
          link={`/search/results?search=${query}`}
        />
      </div>
      <div className="d-2-12 t-2-12 m-2-12 ">
        <span className="subsearchtext">
          Search for products, service providers, industries & locations
        </span>
      </div>
    </div>
  )
}

export default Search
