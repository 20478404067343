export const getCategoryOrIndustry = () => {
  // Get url parts
  const parts = window.location.pathname.toLowerCase().split('/')
  // Splitting by path name should result in 2+ length array
  // expected ['', 'tools', 'category', 'industry']
  if (parts.length > 1 && parts[1] === 'tools') {
    // We only want this to be active on the tools pages
    const cat = parts[2]
    const ind = parts[3] ?? null

    return {
      category: cat,
      industry: ind,
    }
  }

  return {
    category: null,
    industry: null,
  }
}