import HomeCardInfo from "../components/home_card/HomeCardInfo";
import HomeCard from "../components/home_card/HomeCard";
import grow from '../assets/images/row.png'
import website from '../assets/images/website.png'
import manage from '../assets/images/manage.png'
import marketing from '../assets/images/market.png'
import accounting from '../assets/images/accounting.png'
import { JSX } from "react";

export default function LandingCards(): JSX.Element {
    return (
        <div className="gc no-gap center_all content">
            <div className="d-2-12 t-2-12 m-3-11 text_center iconsBelow">
                <h2 className="padding_h2">
                    It's hard work running a business,{' '}
                    <span className="blue">but having the right tools helps</span>
                </h2>

                <p className="main_p">
                    The Right Tool is a free marketplace of business apps, tailored to
                    help Kiwi businesses like yours find the right tool for your business.
                </p>
            </div>

            <div className="d-2-12 t-2-12 m-1-13 gc profile">
                <HomeCard title={['To build your', 'website',]}
                          titleRed={[1]}
                          copy={"So you can get an online storefront."}
                          href={"/tools/website/"}
                          mainImg={website} />
                <HomeCard title={['To manage your', 'accounting']}
                          titleRed={[1]}
                          copy={"So you can manage your cash flow."}
                          href={"/tools/accounting/"}
                          mainImg={accounting} />

                <HomeCard title={['To help you', 'market', 'your business']}
                          titleRed={[1]}
                          copy={"So you can win more business."}
                          href={"/tools/marketing/"}
                          mainImg={marketing} />
                <HomeCard title={['To help you', 'grow', 'your business']}
                          titleRed={[1]}
                          copy={"So you can turn your data into actionable insights."}
                          href={"/tools/growth/"}
                          mainImg={grow} />

                <HomeCard title={['To help you', 'manage', 'your business']}
                          titleRed={[1]}
                          copy={"So you can improve your productivity."}
                          href={"/tools/managing-my-business/"}
                          mainImg={manage} />
                <HomeCardInfo title={'Not sure where to start?'}
                              subtitle={'Try the Free Tool Finder'}
                              copy={'Get tailored app recommendations specific to your business for free.'}
                              href={'/free-tool-finder'}

                />
            </div>
        </div>
    )
}