import Cookie from 'js-cookie'
import { stringify } from 'qs'
import { API_URL } from '../constraints'

function qs(obj: Record<string, unknown>) {
    return stringify(obj, {encodeValuesOnly: true})
}

const API = {
    get fetchHeaders() {
        const token = Cookie.get('AUTH_TOKEN') ? Cookie.get('AUTH_TOKEN') : null
        if (token) {
            return {
                'Content-Type': 'application/json',
                Authorization: `${token}`,
            } as RequestInit
        }

        return {
            'Content-Type': 'application/json',
        } as RequestInit
    },

    get: async (url: string, converter?: ConverterFunction, converterProps?: any) => {
        return await fetch(`${API_URL}/api${url}`, API.fetchHeaders)
            .then((response) => {
                if (response.status === 200) {
                    return response.json()
                }
            })
            .then(responseData => {
                if (responseData) {
                    if (converter) {
                        return converter(responseData, converterProps ?? null)
                    }

                    return responseData
                }
            })
            .catch(e => null)
    },

    // post: async (url, body, converter) => {
    //
    // },
}

enum ContentTypesMapping {
    accounts = 'account',
    apps = 'app',
    categories = 'category',
    industries = 'industry',
    likes = 'like',
    locations = 'location',
    reviews = 'review',
    services = 'service',
    subcategories = 'subcategory',
    'team-members' = 'team-member'
}

type Collection = keyof typeof ContentTypesMapping;
type SlugCollection = typeof ContentTypesMapping[keyof typeof ContentTypesMapping];
type ConverterFunction = <T = Record<string, unknown>>(data: Record<string, unknown>, extraOptions?: any) => T;

export async function getEntryBySlug(slug: string, collection: SlugCollection, converter?: ConverterFunction, converterProps?: any) {
    const url = `/${collection}/${slug}`;
    return await API.get(url, converter, converterProps);
}

export async function getEntryById(id: number, collection: Collection, converter?: ConverterFunction, converterProps?: any) {
    const url = `/${collection}/${id}`;
    return await API.get(url, converter, converterProps);
}

export async function getEntriesById(ids: number[], collection: Collection, converter?: ConverterFunction, converterProps?: any) {
    let url = `/${collection}`
    url += '?' + qs({
        filters: {
            id: {
                '$in': ids,
            },
        },
    })

    return await API.get(url, converter, converterProps)
}

