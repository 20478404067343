import React, { Component } from 'react'
import './footer.scss'
import logoblue from '../../assets/images/logo_blue.png'

import dboost from '../../assets/images/dboost.png'
import Cookie from 'js-cookie'
import { ADMIN_URL } from '../../constraints'

const token = Cookie.get('AUTH_TOKEN') ? Cookie.get('AUTH_TOKEN') : null
const secure = window.location.protocol === 'https'

class Footer extends Component {
  render () {
    return (
      <div id="footer" className="content">
        <a className="skiplink" href="#footer">
          Skip To Menu
        </a>
        <div className="gc no-gap">
          <div className="d-2-5 t-2-5 m-hide image borderRight">
            <a href="/" title="return to the homepage">
              <img
                className="footer-logo"
                src={logoblue}
                alt="The Right Tool Logo"
              ></img>
            </a>
          </div>
          <div className="d-6-8 t-6-9 m-2-12">
            <div className="footer-links">
              <span className={token && 'hide'}>
                <a href={ADMIN_URL + '/auth/login'}
                   title="sign in">
                  Sign In
                </a>
              </span>

              <span className={token && 'hide'}>
                <a href={ADMIN_URL + '/auth/registration'}
                   title="Create an account">
                  Create Account
                </a>
              </span>
              <span>
                <a href="/" title="redirect back home">
                  App Directory
                </a>
              </span>
              <span>
                <a href="/" title="redirect back home">
                  Cloud Experts
                </a>
              </span>
            </div>
          </div>

          <div className="d-9-12 t-9-12 m-2-12 ">
            <div className="footer-links">
              <span>
                <a href="/free-tool-finder" content="our story page">
                  Free Tool Finder
                </a>
              </span>
              <span>
                <a href="/story" content="our story page">
                  Our Story
                </a>
              </span>
              <span>
                <a href="/contact-us" content="Contact us page">
                  Contact Us
                </a>
              </span>
              <span>
                <a href="/sitemap.xml" content="sitemaps">
                  Sitemap
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="gc no-gap">
          <div className="d-2-5 t-2-5 m-2-12 m-hide borderRight">
            <div className="footer-links">
              <span>
                <a href="/privacy-policy" content="priviacy policy">
                  Privacy Policy
                </a>
              </span>
              <span>
                <a href="/terms-of-use" content="terms of use">
                  Terms & Conditions
                </a>
              </span>
              <span className="sub_span">© The Right Tool, 2021</span>
            </div>
          </div>
          <div className="d-6-8 t-6-9 m-2-12">
            <div className="footer-links">
              <span>
                <a href="/list-an-app" content="list your app">
                  List your app
                </a>
              </span>
              <span>
                {' '}
                <a
                  href="/list-a-cloud-expert"
                  content="List your cloud expert profile">
                  List your cloud expert profile
                </a>
              </span>
            </div>
          </div>
          <div className="d-9-12 t-hide m-hide">
            <div className="footer-links">
              <span className="supportFooter">In Partnership with</span>
              <a href="https://digitalboostalliance.nz//"
                 title="open external link">
                <img
                  className="footer-gov"
                  src={dboost}
                  alt="Digital boost logo"
                ></img>
              </a>
            </div>
          </div>
          <div className="twologos deskhide t-9-12 m-2-12">
            <div className="footer-links">
              <span className="supportFooter">In Partnership with</span>
              <a href="https://digitalboostalliance.nz/"
                 title="open external link">
                <img
                  className="dboost footer-gov individual"
                  src={dboost}
                  alt="Digital boost logo"
                ></img>
              </a>
            </div>
          </div>

          <div className="deskhide thide m-2-12 image borderRight">
            <img className="footer-logo" src={logoblue} alt="logo"></img>
          </div>
          <div className="deskhide thide m-2-12">
            <div className="footer-links">
              <span>
                <a href="/privacy-policy" content="priviacy policy">
                  Privacy Policy
                </a>
              </span>
              <span>
                <a href="/terms-of-use" content="terms of use">
                  Terms & Conditions
                </a>
              </span>
              <span className="sub_span">© The Right Tool, 2021</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
